import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

import chatActions from '../redux/chat/actions'

const SoundComponent = () => {
  const chat = useSelector(state => state.chat)
  const dispatch = useDispatch()

  const stopPlaying = () => {
    dispatch(chatActions.stopSound())
  }

  return (
    <ReactPlayerContainer>
      <ReactPlayer
        url={chat.sound.file}
        playing={chat.sound.playing}
        onEnded={stopPlaying}
        style={{ display: 'none' }}
      />
    </ReactPlayerContainer>
  )
}

export default SoundComponent

const ReactPlayerContainer = styled.div`
    position: absolute;
    left: 100vw;
`
