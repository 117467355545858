import * as React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import Linkify from 'linkify-react'
import { useDispatch } from 'react-redux'
import messageActions from '../redux/messages/actions'

import BotInput from './BotInput'
import File from './File'

import { format } from 'date-fns'
const BOT_TYPES = ['bot', 'bot_faq', 'smartchat', 'phone_auth', 'only_phone', 'only_email', 'email']
const EXCLUDED_MESSAGE_TYPES = ['category', 'note', 'bot-action', 'campaign_not_sale', 'campaign_sale', 'hidden']

const ChatBubble = (props) => {
  const isBot = BOT_TYPES.includes(props.message.type)
  const dispatch = useDispatch()

  const onClickValidateTypeButtons = (button) => {
    if (button.type === 'url') { window.open(button.content, '_blank') } else { dispatch(messageActions.buttonAction(button)) }
  }

  if (!EXCLUDED_MESSAGE_TYPES.includes(props.message.type)) {
    return (
      <ChatBubbleContainer type={props.message.type}>
        { props.message.type !== 'customer' && props.message.type !== 'date'
          ? <OperatorImage src={isBot ? props.chat.botImage : props.message.operatorPhoto} alt={'profile-photo'} title={'Foto de Pefil'} />
          : null
        }
        <InputContainer>
          {
            props.message.type === 'date'
              ? <WrapperDate mobile={props.mobile}>
                <DateField>
                  {props.message.text}
                </DateField>
              </WrapperDate>
              : null
          }
          <Bubble type={props.message.type}>
            {props.message.type !== 'date'
              ? <>
                <ChatBubbleText>
                  <Linkify options={{ target: { url: '_blank' } }}>
                    {parse(props.message.text)}
                  </Linkify>
                </ChatBubbleText>
                { props.message.content && props.message.content.length > 0
                  ? <MediaViewer>
                    <File content={props.message.content} />
                  </MediaViewer>
                  : null
                }
                {
                  props.message.createdAt
                    ? <ChatBubbleHour>
                      {format(new Date(props.message.createdAt), 'HH:mm')}
                    </ChatBubbleHour>
                    : null
                }
              </>
              : null
            }
          </Bubble>
          { props.message.buttons && props.message.buttons.length > 0
            ? <ButtonsContainer>
              { props.message.buttons.map((button, index) => {
                return (
                  <Button key={`button-${index}`} onClick={() => onClickValidateTypeButtons(button)}>
                    <span>{button.label}</span>
                  </Button>
                )
              })}
            </ButtonsContainer>
            : null
          }
          <BotInput message={props.message} registerUser={props.registerUser} />
        </InputContainer>
      </ChatBubbleContainer>
    )
  } else {
    return null
  }
}

export default ChatBubble

const ChatBubbleContainer = styled.div`
    align-self: ${props => props.type === 'customer' ? 'flex-end' : 'flex-start'};
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    p {
        margin: 0;
    }
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const OperatorImage = styled.img`
    background-color: #FFEFDA;
    border-radius: 18px;
    padding: 3px;
    margin-right: 15px;
    width: 30px !important;
    height: 30px !important;
`

const Bubble = styled.div`
    background-color: ${props => props.type === 'customer' ? '#F4F4F4' : props.type === 'bot_faq' ? '#4beaa226' : props.type === 'smartchat' ? '#E8772114' : 'rgba(99,24,225,0.05)'};
    border-radius: 10px;
    width: 295px;
`

const ButtonsContainer = styled.div`
    padding-top: 5px;
    width: 295px;
    position: relative;
`

const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #6318E1;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0 3px 0;
    height: 40px;
    color: #FFFFFF;
    font-family: 'Chatweb Font';
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: rgba(99, 24, 225, 0.9);
    }
`

const ChatBubbleText = styled.div`
    font-family: 'Chatweb Font';
    font-size: 15px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    list-style: initial !important;
    max-width: 280px;
    word-break: break-word;
    white-space: pre-line;
`

const MediaViewer = styled.div`
    overflow: hidden;
    width: 100%;
`

const ChatBubbleHour = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 5px 5px;
    font-size: 11px;
    color: #757575;

`

const WrapperDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8px;
  width: ${props => props.mobile ? '100vw' : '390px'};
`

const DateField = styled.div`
  justify-content: center;
  align-items: center;
  background-color: #E8772180;
  border-radius: 5px;
  padding: 3px 8px;
  display: flex;
  font-weight: bold;
  color: #555555;
  font-size: 12px;
`
