import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import reducers from './reducers'

const middleware = [thunk]

if (process.env.REACT_APP_BRANCH === 'development' || process.env.REACT_APP_BRANCH === 'local') {
  middleware.push(createLogger({ collapsed: true }))
}

const store = createStore(
  reducers,
  applyMiddleware(...middleware)
)

export default store
