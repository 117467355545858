import * as React from 'react'
import styled from 'styled-components'
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import { useSelector } from 'react-redux'

import { baseImagesUrl } from '../constants/index.js'

const AudioRecorder = (props) => {
  const [hasPermissions, setHasPermissions] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [recordState, setRecordState] = React.useState(RecordState.NONE)
  const [audio, setAudio] = React.useState(null)
  const chat = useSelector((state) => state.chat)
  const { translation } = useSelector((state) => state.internationalization)

  React.useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        setHasPermissions(true)
      })
      .catch(() => {
        setErrorMessage(translation.allowMicrophone)
      })
  }, [])

  const startAudioRecording = () => {
    setRecordState(RecordState.START)
  }

  const stopAudioRecording = () => {
    setRecordState(RecordState.STOP)
  }

  // audioData contains blob and blobUrl
  const onStop = (audioData) => {
    setAudio(audioData)
  }

  const discardAudio = () => {
    setAudio(null)
  }

  return (
    <AudioRecorderContainer>
      <Waveform>
        <AudioReactRecorder
          state={recordState}
          onStop={onStop}
          foregroundColor={chat.primaryColor}
          canvasWidth={532}
          canvasHeight={105}
        />
      </Waveform>
      {audio ? (
        <AudioPlayerContainer>
          <audio controls src={audio.url} type="audio/wav" />
          <AudioRecorderButton
            color={chat.primaryColor}
            onClick={() => {
              props.uploadAudio(audio)
              setAudio(null)
            }}
          >
            <AudioRecorderIcon
              src={`${baseImagesUrl}cloud-upload.svg`}
              alt={'upload'}
              title={'Upload recording'}
            />
          </AudioRecorderButton>
          <AudioRecorderButton color={chat.primaryColor} onClick={discardAudio}>
            <AudioRecorderIcon
              src={`${baseImagesUrl}redo-alt-solid.svg`}
              alt={'redo'}
              title={'Redo recording'}
            />
          </AudioRecorderButton>
        </AudioPlayerContainer>
      ) : (
        <>
          {hasPermissions ? (
            <AudioRecorderButton
              color={chat.primaryColor}
              onClick={
                recordState === RecordState.START
                  ? stopAudioRecording
                  : startAudioRecording
              }
            >
              <AudioRecorderIcon
                src={
                  recordState === RecordState.START
                    ? `${baseImagesUrl}square-solid.svg`
                    : `${baseImagesUrl}microphone-white.svg`
                }
                alt={recordState === RecordState.START ? 'stop' : 'play'}
                title={
                  recordState === RecordState.START
                    ? 'Stop recording'
                    : 'Play recording'
                }
              />
            </AudioRecorderButton>
          ) : (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </>
      )}
    </AudioRecorderContainer>
  )
}

export default AudioRecorder

const AudioRecorderContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0.8;
  flex-direction: column;
  justify-content: center;
`

const Waveform = styled.div`
  background-color: #f4f4f4;
  border-radius: 4px;
  margin-bottom: 30px;
  width: 532px;
  height: 105px;
`

const AudioRecorderButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 27px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 54px;
  height: 54px;
`

const AudioRecorderIcon = styled.img`
  width: 29px !important;
  height: 29px !important;
`

const AudioPlayerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 450px;
`

const ErrorMessage = styled.div`
  color: red;
  font-family: "Raleway";
`
