import { CODE_VALIDATED } from '../auth/types'
import { ADD_WALL } from '../messages/types'
import { USER_LOGGED, USER_WALL_ID } from './types'

const initialState = {
  userId: '',
  wallId: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
  case CODE_VALIDATED:
  case USER_LOGGED:
    return {
      ...state,
      userId: action.userId
    }
  case USER_WALL_ID:
  case ADD_WALL:
    return {
      ...state,
      wallId: action.wallId
    }
  case 'RESET_AUTH':
    return initialState
  default:
    return state
  }
}
