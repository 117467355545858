import { channelToken } from './CToken'

const KEY_ST = `nx_info_${channelToken}`

/**
 * Get all object
 * @returns {{cid: string, isOpen: boolean}}
 */
const get = () => {
  const data = localStorage.getItem(KEY_ST)

  return data ? JSON.parse(data) : { cid: '', isOpen: false, ref: '' }
}

/**
 * Get tracking id
 * @returns {string}
 */
const getCid = () => {
  return get()?.cid || ''
}

/**
 * Get referrer
 * @returns {string}
 */
const getReferrer = () => {
  return get()?.ref || ''
}

/**
 * Set values for localstorage
 * @param {object} params
 * @param {string} [params.cid]
 * @param {string} [params.isOpen]
 * @param {string} [params.ref]
 *
 * @returns {boolean}
 */
const set = (params) => {
  if (!params) return true
  if (!Object.keys(params).length) return true

  const data = get()
  if (params.cid) data.cid = params.cid
  if (params.isOpen) data.isOpen = params.isOpen
  if (params.ref) data.ref = params.ref

  localStorage.setItem(KEY_ST, JSON.stringify(data))

  return true
}

const remove = () => {
  localStorage.removeItem(KEY_ST)
}

export default {
  get,
  getCid,
  getReferrer,
  set,
  remove
}
