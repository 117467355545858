import { SMS_SENT, CODE_VALIDATED, SET_AUTH_ERROR } from './types'

const initialState = {
  authenticationRequired: false,
  registrationStarted: false,
  registrationFinished: false,
  stepOneError: '',
  stepTwoError: '',
  phoneToken: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
  case SMS_SENT:
    return {
      ...state,
      registrationStarted: true,
      phoneToken: action.phoneToken
    }
  case CODE_VALIDATED:
    return {
      ...state,
      registrationFinished: true,
      stepOneError: '',
      stepTwoError: ''
    }
  case SET_AUTH_ERROR:
    return {
      ...state,
      stepOneError: action.step === 1 ? action.errorText : '',
      stepTwoError: action.step === 2 ? action.errorText : ''
    }
  case 'RESET_AUTH':
    return initialState
  default:
    return state
  }
}
