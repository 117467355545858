import * as React from 'react'
import AudioPlayer from 'react-h5-audio-player'
import styled from 'styled-components'
import { hexToCSSFilter } from 'hex-to-css-filter'

import './styles.scss'
import { baseImagesUrl } from '../../constants'

const Player = (props) => (
  <CustomAudioPlayer
    color={hexToCSSFilter(props.color)}
    src={props.src}
    //   onPlay={e => console.log("onPlay")}
    showJumpControls={false}
    layout='horizontal'
    customAdditionalControls={[]}
    customVolumeControls={[]}
    customIcons={{
      play: (
        <AudioButton>
          <AudioIcon color={hexToCSSFilter(props.color)} src={`${baseImagesUrl}play-solid.svg`} alt={'play'} title={'Reproducir'} />
        </AudioButton>
      ),
      pause: (
        <AudioButton>
          <AudioIcon color={hexToCSSFilter(props.color)} src={`${baseImagesUrl}pause-solid.svg`} alt={'pause'} title={'Pausar'} />
        </AudioButton>
      )
    }}
  />
)

export default Player

const CustomAudioPlayer = styled(AudioPlayer)`
  .rhap_progress-indicator {
    background-color: ${props => props.color};
  }
  .rhap_progress-filled {
    background-color: ${props => props.color};
  }
`

const AudioButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const AudioIcon = styled.img`
  filter: ${props => props.color};
  object-fit: scale-down;
  width: 60% !important;
  height: 60% !important;
`
