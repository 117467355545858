import * as React from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import { useSelector } from 'react-redux'

import { baseImagesUrl } from '../constants'

const PhotoAndVideoRecorder = (props) => {
  const [cameraReady, setCamerReady] = React.useState(false)
  const [mediaType, setMediaType] = React.useState(null)
  const [media, setMedia] = React.useState(null)
  const [capturing, setCapturing] = React.useState(false)
  const [recordedChunks, setRecordedChunks] = React.useState([])
  const chat = useSelector(state => state.chat)
  const webcamRef = React.useRef(null)
  const mediaRecorderRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setMediaType('image')
    setMedia(imageSrc)
  }, [webcamRef])

  const videoConstraints = {
    width: 620,
    height: 345,
    facingMode: 'user'
  }

  const upload = () => {
    if (mediaType === 'image') {
      props.uploadPhoto(media)
    } else {
      props.uploadVideo(media)
    }
  }

  const discard = () => {
    setCamerReady(false)
    setMediaType(null)
    setMedia(null)
  }

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm'
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }, [webcamRef, setCapturing, mediaRecorderRef])

  const handleDataAvailable = React.useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data))
    }
  }, [setRecordedChunks])

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef, setCapturing])

  React.useEffect(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm'
      })
      setMedia(blob)
      setMediaType('video')
      setRecordedChunks([])
    }
  }, [recordedChunks])

  return (
    <VideoRecorderContainer>
      <StreamContainer>
        { media
          ? <>
            { mediaType === 'image'
              ? <ImageDisplay src={media} alt={'photo'} title={'Foto'}/>
              : <VideoDisplay controls>
                <source src={URL.createObjectURL(media)} type='video/webm' />
              </VideoDisplay>
            }
          </>
          : <Webcam
            audio
            height={345}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={620}
            videoConstraints={videoConstraints}
            onUserMedia={() => {
              setCamerReady(true)
            }}
          />
        }
      </StreamContainer>
      { cameraReady
        ? <ButtonsContainer>
          { media
            ? <>
              <ActionButton color={chat.primaryColor} onClick={upload}>
                <VideoRecorderIcon src={`${baseImagesUrl}cloud-upload.svg`} alt={'upload'} title={'Subir captura'}/>
              </ActionButton>
              <ActionButton color={chat.primaryColor} onClick={discard}>
                <VideoRecorderIcon src={`${baseImagesUrl}redo-alt-solid.svg`} alt={'redo'} title={'Volver a capturar'}/>
              </ActionButton>
            </>
            : <>
              <ActionButton color={chat.primaryColor} onClick={capture}>
                <VideoRecorderIcon src={`${baseImagesUrl}camera-white.svg`} alt={'capture'} title={'Tomar foto'}/>
              </ActionButton>
              <ActionButton color={chat.primaryColor} onClick={capturing ? handleStopCaptureClick : handleStartCaptureClick}>
                <VideoRecorderIcon src={capturing ? `${baseImagesUrl}square-solid.svg` : `${baseImagesUrl}video-white.svg`}
                  alt={capturing ? 'stop' : 'camera'}
                  title={capturing ? 'Detener grabación' : 'Empezar grabación'}
                />
              </ActionButton>
            </>
          }
        </ButtonsContainer>
        : null
      }
    </VideoRecorderContainer>
  )
}

export default PhotoAndVideoRecorder

const VideoRecorderContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
`

const StreamContainer = styled.div`
    width: 620px;
    height: 345px;
    margin-bottom: 15px;
`

const ButtonsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
    width: 25%;
`

const ActionButton = styled.div`
    align-items: center;
    background-color: ${props => props.color};
    border-radius: 27px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 54px;
    height: 54px;
`

const VideoRecorderIcon = styled.img`
    width: 29px !important;
    height: 29px !important;
`

const ImageDisplay = styled.img`
    display: flex;
    flex: 1 !important;
`

const VideoDisplay = styled.video`
    width: 620px;
    height: 345px;
`
