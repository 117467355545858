import { WALL_API } from '../constants'
import handleSocketEvent from '../redux/handleSocketAct'

import SocketConnection from './SocketConnection'

const { SOCKET: wallAPIOptions } = WALL_API

const SocketWall = new SocketConnection(wallAPIOptions, handleSocketEvent)

export { SocketWall }

export default {
  startAll () {
    SocketWall.start()
  },
  destroyAll () {
    SocketWall.destroy()
  }
}
