import * as React from 'react'
import styled from 'styled-components'

import { baseImagesUrl } from '../constants'

const FileAttached = (props) => {
  const [hover, setHover] = React.useState(false)
  const getFileAttachedIcon = (file) => {
    switch (file.type.split('/')[0]) {
    case 'audio':
      return `${baseImagesUrl}microphone-icon.svg`
    case 'video':
      return `${baseImagesUrl}video-solid.svg`
    case 'image':
      return file.content// `${baseImagesUrl}image-solid.svg`
    case 'application':
      return `${baseImagesUrl}file-regular.svg`
    default:
      return ''
    }
  }

  return (
    <File
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.removeAttached}
    >
      <FileIcon src={ hover ? `${baseImagesUrl}trash-alt-solid.svg` : getFileAttachedIcon(props.file)} alt={'trash'} title={'Borrar'}/>
    </File>
  )
}

export default FileAttached

const File = styled.div`
    align-items: center;
    background-color: rgba(100,17,219,0.1);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    overflow: hidden;
    width: 40px;
    height: 40px;
`

const FileIcon = styled.img`
    align-items: center;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 20px !important;
    height: 20px !important;
`
