import store from './store'
import messageActions from './messages/actions'
import chatActions from './chat/actions'

const { dispatch } = store

export default async (e) => {
  try {
    const event = JSON.parse(e)

    switch (event.action) {
    /**
       *  SOCKET WALL_API CHANNEL --> user._id
       */
    case 'newNode': {
      switch (event.data.type) {
      case 'bot-action':
      case 'campaign_not_sale':
      case 'campaign_sale':
      case 'note':
        break
      default:
        dispatch(messageActions.addNewOperatorMessage(event.data))
        if (event.data.type === 'operator') {
          dispatch(chatActions.changeOperator({
            operatorName: event.data.postedBy.name,
            operatorPhoto: event.data.postedBy?.photoURL
          }))
          dispatch(chatActions.playRecieveMessage())
        }
        break
      }
      break
    }
    case 'updateUserOrder':
      break
    case 'writting':
      dispatch(chatActions.setOperatorWriting(event.data))
      break
    default:
      console.error('Socket Event -> No action found for data:', event)
    }
  } catch (e) {
    console.error('Socket Error -> ', e.message)
  }
}
