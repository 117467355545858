import * as React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Picker from 'emoji-picker-react'
import { hexToCSSFilter } from 'hex-to-css-filter'

import messageActions from '../redux/messages/actions'
import chatActions from '../redux/chat/actions'
import ChatBubble from './ChatBubble'

import FileAttached from './FileAttached'

import { baseImagesUrl } from '../constants'
import { registerUser } from '../helpers/authHelpers'
import EmailSend from './EmailSendModal'
import stylesForChatScreenSize from '../helpers/stylesForChatScreenSize'

const ChatScreen = (props) => {
  const messages = useSelector((state) => state.messages)
  const auth = useSelector((state) => state.auth)
  const chat = useSelector((state) => state.chat)
  const { translation } = useSelector(state => state.internationalization)
  const user = useSelector((state) => state.user)
  const [, setCookie] = useCookies(['chatweb-cookie'])
  const [message, setMessage] = React.useState('')
  const [filesAttached, setFilesAttached] = React.useState([])
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false)
  const [showEmailSend, setShowEmailSend] = React.useState(false)
  const messagesContainer = React.createRef()
  let filePicker = React.useRef()
  const dispatch = useDispatch()

  React.useEffect(() => {
    messagesContainer.current.scrollIntoView(false)
  }, [messages])

  React.useEffect(() => {
    async function attachAudio () {
      if (chat.recordedAudio) {
        const result = await toBase64(chat.recordedAudio.blob).catch(e => Error(e))
        if (result instanceof Error) {
          console.error('Error: ', result.message)
          return
        }
        const fileToUpload = {
          name: chat.recordedAudio.blob.name,
          type: chat.recordedAudio.blob.type,
          content: result
        }
        dispatch(chatActions.attachAudioRecording(null))
        setFilesAttached([...filesAttached, fileToUpload])
      }
    }
    attachAudio()
  }, [chat.recordedAudio])

  React.useEffect(() => {
    async function attachVideo () {
      if (chat.recordedVideo) {
        const result = await toBase64(chat.recordedVideo).catch(e => Error(e))
        if (result instanceof Error) {
          console.error('Error: ', result.message)
          return
        }
        const fileToUpload = {
          name: 'recording.webm',
          type: 'video/webm',
          content: result
        }
        dispatch(chatActions.attachVideoRecording(null))
        setFilesAttached([...filesAttached, fileToUpload])
      }
    }
    attachVideo()
  }, [chat.recordedVideo])

  React.useEffect(() => {
    if (chat.capturedPhoto) {
      const fileToUpload = {
        name: 'photo.jpeg',
        type: 'image/jpeg',
        content: chat.capturedPhoto
      }
      dispatch(chatActions.attachPhoto(null))
      setFilesAttached([...filesAttached, fileToUpload])
    }
  }, [chat.capturedPhoto])

  const register = (inputValue, type) => {
    registerUser(inputValue, setCookie, type)
  }

  const getKeycode = (e) => {
    if (e.keyCode === 13) {
      if (message !== '') {
        sendMessage()
      }
      e.preventDefault()
    }
  }

  const sendMessage = () => {
    dispatch(messageActions.addNewUserMessage(`<p>${message}</p>`, filesAttached))
    setMessage('')
    setFilesAttached([])
  }

  const onEmojiClick = (event, emojiObject) => {
    setMessage(`${message}${emojiObject.emoji}`)
  }

  const onChangeFile = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files[0]
    const result = await toBase64(file).catch(e => Error(e))
    if (result instanceof Error) {
      console.error('Error: ', result.message)
      return
    }
    const fileToUpload = {
      name: file.name,
      type: file.type,
      content: result
    }
    setFilesAttached([...filesAttached, fileToUpload])
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const removeAttachedFile = (indexToRemove) => {
    const newFilesAttached = filesAttached.filter((file, index) => {
      return index !== indexToRemove
    })
    setFilesAttached([...newFilesAttached])
  }

  return (
    <ChatWindow style={stylesForChatScreenSize({ mode: props.mode, isStandalone: true })}>
      <Header color={chat.primaryColor}>
        <OperatorInfoContainer>
          <ProfileImage src={chat.operatorInfo.operatorPhoto === '' ? chat.botImage : chat.operatorInfo.operatorPhoto} alt={'profile-photo'} title={'Foto de Pefil'} />
          <OperatorNameContainer>
            { chat.operatorInfo.operatorName !== ''
              ? <OperatorName>{chat.operatorInfo.operatorName}</OperatorName>
              : null
            }
            <OperatorCompany>{chat.operatorInfo.operatorCompany}</OperatorCompany>
          </OperatorNameContainer>
        </OperatorInfoContainer>
        <WrapperIcons>
          <CloseIcon src={`${baseImagesUrl}icon-maximize-normal.svg`} onClick={() => props.setMode('normal')} size='normal' alt='max-normal' title='Ventana normal' />
          <CloseIcon src={`${baseImagesUrl}icon-maximize-normal.svg`} onClick={() => props.setMode('medium')} size='medium' alt='max-medium' title='Ventana mediana' />
          <CloseIcon src={`${baseImagesUrl}icon-maximize-normal.svg`} onClick={() => props.setMode('long')} size='long' alt='max-long' title='Ventana grande' />
        </WrapperIcons>
      </Header>
      <ChatContainer>
        <MessagesContainer ref={messagesContainer}>
          { messages.map((message, index) => {
            return <ChatBubble key={index} message={message} registerUser={register} auth={auth} chat={chat} />
          })}
          { chat.operatorWriting
            ? <OperatorWritingText>{translation.writing}</OperatorWritingText>
            : null
          }
        </MessagesContainer>
      </ChatContainer>
      { user.userId !== ''
        ? <>
          <ChatInputSeparator />
          <ChatInputContainer>
            <ChatInput
              value={message}
              onKeyDown={(e) => getKeycode(e)}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={translation.writeHere}
            />
            <ChatInputOptionsContainer>
              <ChatSendButton
                color={(message !== '' || filesAttached.length > 0) ? chat.primaryColor : 'grey'}
                onClick={(message !== '' || filesAttached.length > 0) ? sendMessage : () => {}}
              >
                <ChatSendIcon src={`${baseImagesUrl}chevron-right-solid.svg`} alt={'send'} title={'Enviar'} />
              </ChatSendButton>
              { navigator.userAgent.indexOf('Chrome') !== -1
                ? <>
                  <ChatInputOption
                    src={`${baseImagesUrl}camera-solid.svg`}
                    onClick={() => dispatch(chatActions.toggleVideoModal())}
                    color={hexToCSSFilter(chat.primaryColor)}
                    alt={'photo'}
                    title={'Tomar foto'}
                  />
                  <ChatInputOption
                    src={`${baseImagesUrl}microphone-solid.svg`}
                    onClick={() => dispatch(chatActions.toggleAudioModal())}
                    color={hexToCSSFilter(chat.primaryColor)}
                    alt={'audio'}
                    title={'Grabar nota de voz'}
                  />
                </>
                : null
              }
              <ChatInputOption
                src={`${baseImagesUrl}smile-solid.svg`}
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                color={hexToCSSFilter(chat.primaryColor)}
                alt={'emoji'}
                title={'Agregar emoji'}
              />
              <ChatInputOption
                src={`${baseImagesUrl}paperclip-solid.svg`}
                onClick={() => filePicker.click()}
                color={hexToCSSFilter(chat.primaryColor)}
                alt={'add file'}
                title={'Agregar adjunto'}
              />
              {
                chat?.authenticationType !== 'redirectFromChannel' && (
                  <ChatInputOption
                    src={`${baseImagesUrl}envelope-solid.svg`}
                    onClick={() => {
                      setShowEmojiPicker(false)
                      setShowEmailSend(!showEmailSend)
                    }}
                    color={hexToCSSFilter(chat.primaryColor)}
                    alt={'semd email'}
                    title={'Enviar por correo'}
                  />
                )
              }
            </ChatInputOptionsContainer>
          </ChatInputContainer>
          { filesAttached.length > 0
            ? <FilesAttachedContainer>
              { filesAttached.map((file, index) => {
                return (
                  <FileAttached file={file} key={index} removeAttached={() => removeAttachedFile(index)} />
                )
              })}
            </FilesAttachedContainer>
            : null
          }
          { showEmojiPicker
            ? <EmojiPickerContainer>
              <Picker onEmojiClick={onEmojiClick} />
            </EmojiPickerContainer>
            : null
          }
        </>
        : null
      }
      <input id="filePicker"
        type="file"
        ref={(ref) => (filePicker = ref)}
        style={{ display: 'none' }}
        onChange={(e) => onChangeFile(e)}
      />
      <EmailSend showEmailSend={showEmailSend} setShowEmailSend={setShowEmailSend} />
    </ChatWindow>
  )
}

export default ChatScreen

const ChatWindow = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 20px;
    width: 425px;
    height: 550px;
    z-index: 2147483641;
`

const Header = styled.div`
    align-items: center;
    background-color: ${props => props.color};
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 20px;
    width: '100%';
    min-height: 65px;
`

const OperatorInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const ProfileImage = styled.img`
    background-color: #FFEFDA;
    border-radius: 21px;
    margin-right: 10px;
    padding: 3px;
    width: 32px !important;
    height: 32px !important;
`

const OperatorNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const OperatorName = styled.div`
    color: #FFFFFF;
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 800;
`

const OperatorCompany = styled.div`
    color: white;
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
`

const WrapperIcons = styled.div`
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`

const CloseIcon = styled.img`
    cursor: pointer;
    opacity: 0.5;
    width: ${props => props.size === 'normal' ? '10px' : props.size === 'medium' ? '14px' : '18px'} !important;
    height: ${props => props.size === 'normal' ? '10px' : props.size === 'medium' ? '14px' : '18px'} !important;
`

const ChatContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow-y: scroll;
`

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: initial;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
`

const ChatInputContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`

const ChatInputSeparator = styled.div`
    background-color: #E4E4E4;
    width: 100%;
    height: 2px;
`

const ChatInput = styled.input`
    border: none;
    border-color: transparent;
    color: #333333;
    display: flex;
    font-size: 16px;
    line-height: 30px;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 0px transparent;
    }
    width: 45%;
`

const ChatSendButton = styled.div`
    align-items: center;
    background-color: ${props => props.color};
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
`

const ChatSendIcon = styled.img`
    height: 20px !important;
`

const ChatInputOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 15px;
    width: 55%;
`

const ChatInputOption = styled.img`
    cursor: pointer;
    filter: ${props => props.color};
    margin-left: 15px;
    height: 20px !important;
`

const EmojiPickerContainer = styled.div`
    position: absolute;
    bottom: 165px;
    right: 450px;
`

const FilesAttachedContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
`

const OperatorWritingText = styled.div`
    font-family: 'Chatweb Font';
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    opacity: 0.4;
`
