import { combineReducers } from 'redux'
import auth from './auth/reducer'
import messages from './messages/reducer'
import user from './user/reducer'
import chat from './chat/reducer'
import internationalization from './internationalization/reducer'

const rootReducer = combineReducers({
  auth,
  messages,
  user,
  chat,
  internationalization
})

export default rootReducer
