import { format } from 'date-fns'
import { es, ca, enGB, fr, de } from 'date-fns/locale'

import { channelToken } from '../services/CToken'
import store from '../redux/store'
import wallApi from '../api/wall'
import authActions from '../redux/auth/actions'

const { dispatch, getState } = store

const locales = { es, ca, en: enGB, fr, de }

const registerUser = (inputValue, setCookie, type) => {
  const auth = getState().auth
  if (auth.registrationStarted) { // Code Validation
    dispatch(authActions.validatePhone(inputValue, type)).then((userId) => {
      const expireDate = new Date()
      expireDate.setHours(expireDate.getHours() + 12)
      // eslint-disable-next-line no-undef, no-lone-blocks
      { (process.env.REACT_APP_STANDALONE && process.env.REACT_APP_STANDALONE === 'true')
        ? setCookie(`chatweb-cookie-standalone-${window.location.pathname.split('/')[2]}`, userId, { path: '/', expires: expireDate })
        : setCookie('chatweb-cookie', { userId, channelToken }, { path: '/', expires: expireDate })
      }
    })
  } else {
    if (auth.registrationFinished) {
      if (type === 'only_email' || type === 'only_phone') {
        const userId = inputValue
        dispatch(authActions.codeValidated(userId, true, type))
        const expireDate = new Date()
        expireDate.setHours(expireDate.getHours() + 12)
        // eslint-disable-next-line no-undef, no-lone-blocks
        { (process.env.REACT_APP_STANDALONE && process.env.REACT_APP_STANDALONE === 'true')
          ? setCookie(`chatweb-cookie-standalone-${window.location.pathname.split('/')[2]}`, userId, { path: '/', expires: expireDate })
          : setCookie('chatweb-cookie', { userId, channelToken }, { path: '/', expires: expireDate })
        }
      }
    } else {
      dispatch(authActions.userLoginPhone(inputValue, type))
    }
  }
}

const updateUserState = (userId, online = false) => {
  const id = userId || getState().user.userId

  if (id) {
    wallApi.updateUserState({
      userId: id,
      online
    })
  }
}

const formatDateLong = (date, options = {}) => {
  const { locale = 'es', longText = 'dd \'de\' MMMM \'de\' yyyy' } = options

  return format(date, longText, { locale: locales[locale] })
}

const nodeForDate = (messageText, date) => ({
  _id: date.getTime(),
  createdAt: date.toString(),
  messageContent: {
    _id: date.getTime(),
    attachments: [],
    buttons: [],
    createdAt: date.toString(),
    eventType: 'date',
    location: {},
    messageText,
    updatedAt: date.toString()
  },
  rootId: date.getTime(),
  type: 'date',
  updatedAt: date.toString(),
  user: 'app'
})

export {
  registerUser,
  updateUserState,
  formatDateLong,
  nodeForDate
}
