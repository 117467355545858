import {
  ATTACH_AUDIO,
  CHANGE_OPERATOR,
  CLOSE_MODAL,
  TOGGLE_CAMERA_MODAL,
  TOGGLE_MIC_MODAL,
  ATTACH_VIDEO,
  ATTACH_PHOTO,
  SET_COMPANY_TOKEN,
  SET_CHAT_INFORMATION,
  PLAY_SOUND,
  STOP_SOUND,
  SET_OPERATOR_WRITING,
  SET_TAB_STATE,
  ADD_UNREAD_COUNTER,
  RESET_UNREAD_COUNTER,
  SET_SESSION_TOKEN,
  EXPIRE_CHAT
} from './types'

import WallApi from '../../api/wall'
import { baseSoundsUrl } from '../../constants'
import intAction from '../internationalization/actions'
import TrackingEvents from '../../services/TrackingEvents'

const changeOperator = (operatorInfo) => ({
  type: CHANGE_OPERATOR,
  operatorInfo
})

const setCompanyToken = (companyToken) => ({
  type: SET_COMPANY_TOKEN,
  companyToken
})

const setChatInformation = (data) => ({
  type: SET_CHAT_INFORMATION,
  data
})

const addUnreadCounter = () => ({
  type: ADD_UNREAD_COUNTER
})

const resetUnreadCounter = () => ({
  type: RESET_UNREAD_COUNTER
})

const getChannelInformation = () => async (dispatch) => {
  try {
    const { data } = await WallApi.getCompanyToken()
    await dispatch(setCompanyToken(data.token))

    const { data: chInfo } = await WallApi.getChannelInformation(data.token)
    if (chInfo?.authenticationType === 'redirectFromChannel') {
      const sessionToken = new URLSearchParams(window.location.search).get('t')
      dispatch(setSessionToken(sessionToken))
    }
    await dispatch(intAction.updateLocale({ locale: chInfo.locale }))

    dispatch(changeOperator({ operatorCompany: chInfo.company.name, operatorName: chInfo.waitingForText || '' }))
    dispatch(setChatInformation(chInfo))

    await TrackingEvents.start()
    TrackingEvents.addHistory({
      url: window.location.href,
      title: window.document.title,
      referrer: window.document.referrer
    })

    return chInfo.authenticationType
  } catch (error) {
    console.error('info', error.message)
  }
}

const toggleVideoModal = () => ({
  type: TOGGLE_CAMERA_MODAL
})

const toggleAudioModal = () => ({
  type: TOGGLE_MIC_MODAL
})

const closeModal = () => ({
  type: CLOSE_MODAL
})

const attachAudioRecording = (audio) => ({
  type: ATTACH_AUDIO,
  audio
})

const attachVideoRecording = (video) => ({
  type: ATTACH_VIDEO,
  video
})

const attachPhoto = (photo) => ({
  type: ATTACH_PHOTO,
  photo
})

const playSendMessage = () => ({
  type: PLAY_SOUND,
  fileUrl: `${baseSoundsUrl}send_message.mp3`
})

const playRecieveMessage = () => (dispatch) => {
  try {
    dispatch({
      type: PLAY_SOUND,
      fileUrl: `${baseSoundsUrl}recieve_message.mp3`
    })
  } catch (error) {
    console.error('error: ', error)
  }
}

const stopSound = () => ({
  type: STOP_SOUND
})

const setOperatorWriting = (operatorWriting) => ({
  type: SET_OPERATOR_WRITING,
  operatorWriting
})

const setTabState = (isFocused) => ({
  type: SET_TAB_STATE,
  isFocused
})

const setSessionToken = (sessionToken) => ({
  type: SET_SESSION_TOKEN,
  sessionToken
})

const expireChat = () => ({
  type: EXPIRE_CHAT
})

export default {
  getChannelInformation,
  changeOperator,
  toggleVideoModal,
  toggleAudioModal,
  closeModal,
  attachAudioRecording,
  attachVideoRecording,
  attachPhoto,
  playSendMessage,
  playRecieveMessage,
  stopSound,
  setOperatorWriting,
  setTabState,
  addUnreadCounter,
  resetUnreadCounter,
  setCompanyToken,
  setSessionToken,
  expireChat
}
