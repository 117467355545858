import * as React from 'react'
import styled from 'styled-components'
import { useSelector/* , useDispatch */ } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import es from 'react-phone-input-2/lang/es.json'
import './phoneInput.css'

// import authActions from '../redux/auth/actions'

import { baseImagesUrl } from '../constants'

const BotInput = (props) => {
  const chat = useSelector(state => state.chat)
  const auth = useSelector(state => state.auth)
  const { translation } = useSelector(state => state.internationalization)
  const isStepOneDone = (props.message.type === 'phone_auth' || props.message.type === 'email') && props.message.subType === 'stepOne' && auth.registrationStarted
  const isStepTwoDone = (props.message.type === 'phone_auth' || props.message.type === 'email') && props.message.subType === 'stepTwo' && auth.registrationFinished
  const [inputValue, setInputValue] = React.useState('')
  const [phoneCode, setPhoneCode] = React.useState('')
  const [inputError, setInputError] = React.useState('')
  const [countdown, setCountdown] = React.useState(null)
  const [enableRetry, setEnableRetry] = React.useState(false)
  // const dispatch = useDispatch()

    React.useEffect(() => {
        if (isStepOneDone) {
            setCountdown(30)
        }
    }, [isStepOneDone])

    React.useEffect(() => {
        const timer = countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000)
        return () => clearInterval(timer)
    }, [countdown])

    React.useEffect(() => {
        if (countdown === 0) {
            setEnableRetry(true)
        }
    }, [countdown])

  function isNumberKey (evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 187 && charCode !== 16) { evt.preventDefault() }
    return true
  }

  const register = (value) => {
    if (props.message.type === 'phone_auth' || props.message.type === 'only_phone') {
      if (value !== '' && value !== '+' && value.length >= 6) {
        props.registerUser(value, props.message.type === 'phone_auth' ? 'phone' : props.message.type)
        setInputError('')
      } else {
        hasError()
      }
    } else {
      if (value !== '') {
        props.registerUser(value, props.message.type)
        setInputError('')
      } else {
        hasError()
      }
    }
  }

  const hasError = () => {
    if (props.message.subType === 'stepOne') {
      setInputError('stepOne')
    } else {
      setInputError('stepTwo')
    }
  }

  const retry = () => {
    setEnableRetry(false)
    setCountdown(30)
  }

  return (
    <>
      { ((props.message.type === 'phone_auth' || props.message.type === 'only_phone' || props.message.type === 'only_email' || props.message.type === 'email') && props.message.subType !== 'initialStep' && props.message.subType !== 'stepThree')
        ? <BotTextInputBorder>
          <BotTextInputContainer>
            { props.message.subType === 'stepOne'

              ? (props.message.type === 'phone_auth' || props.message.type === 'only_phone')
                ? <PhoneInput
                  country={chat.countryDialCode}
                  value={phoneCode}
                  placeholder=''
                  enableLongNumbers
                  localization={es}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      if (enableRetry) {
                        retry(phoneCode)
                      } else {
                        register(phoneCode)
                      }
                    }
                  }}
                  onChange={phone => setPhoneCode(`+${phone}`)}
                  containerStyle={{
                    width: 'unset'
                  }}
                  inputStyle={{
                    borderColor: inputError === 'stepOne' ? 'red' : 'transparent',
                    width: '240px'
                  }}
                  dropdownStyle={{
                    border: '0px',
                    bottom: '50px'
                  }}
                />
                : <BotTextInput
                  value={phoneCode}
                  placeholder={translation.placeholderMail}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      if (enableRetry) {
                        retry(phoneCode)
                      } else {
                        register(phoneCode)
                      }
                    }
                  }}
                  onChange={({ target }) => setPhoneCode(`${target.value}`)}
                  type='text'
                />
              : <BotTextInput
                placeholder={inputError === 'stepTwo' ? translation.writeCode : translation.writeHere}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    register(inputValue)
                  }
                }}
                onChange={(e) => {
                  if (isNumberKey(e)) {
                    return setInputValue(e.target.value)
                  }
                }}
                type='text'
              />
            }
            { props.message.subType === 'stepOne'
              ? <BotTextInputButton
                buttonColor={(isStepOneDone && !enableRetry) ? '#4BEAA2' : chat.primaryColor}
                onClick={isStepOneDone
                  ? () => {
                    if (enableRetry) {
                      retry(phoneCode)
                    }
                  }
                  : () => register(phoneCode)}
              >
                { (isStepOneDone && !enableRetry)
                  ? <>{countdown > 0 ? countdown : ''}</>
                  : <BotTextInputButtonChervon
                    src={enableRetry ? `${baseImagesUrl}undo-solid.svg` : `${baseImagesUrl}chevron-right-solid.svg`}
                    alt={enableRetry ? 'undo-solid' : 'chevron-right-solid.svg'}
                    title={enableRetry ? 'Redo' : 'Send'}
                  />
                }
              </BotTextInputButton>
              : <BotTextInputButton buttonColor={isStepTwoDone ? '#4BEAA2' : chat.primaryColor}>
                <BotTextInputButtonChervon
                  src={isStepTwoDone ? `${baseImagesUrl}white-check.svg` : `${baseImagesUrl}chevron-right-solid.svg`}
                  onClick={isStepTwoDone ? () => {} : () => register(inputValue)}
                  alt={isStepTwoDone ? 'white-check' : 'chevron-right-solid'}
                  title={isStepTwoDone ? 'Redo' : 'Send'}
                />
              </BotTextInputButton>
            }
          </BotTextInputContainer>
        </BotTextInputBorder>
        : null
      }
      { (props.message.subType === 'stepOne' && enableRetry)
        ? <RetrySMSMessage>{props.message.type === 'phone_auth' ? translation.loginNotCodeSMS : translation.loginNotCodeMail}</RetrySMSMessage>
        : null
      }
      <ServerErrorMessage>{props.message.subType === 'stepOne' ? auth.stepOneError : auth.stepTwoError}</ServerErrorMessage>
    </>
  )
}

export default BotInput

const StyledPhoneInput2 = styled(PhoneInput)`
    &.react-tel-input .form-control {
        background-color: #ffffff !important
    }
`

const BotTextInputBorder = styled.div`
    align-items: center;
    background-color: rgba(99,24,225,0.05);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 295px;
    height: 45px;
`

const BotTextInputContainer = styled.div`
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 285px;
    height: 35px;
`

const BotTextInput = styled.input`
    border: none !important;
    border-color: transparent;
    color: #333333 !important;
    font-family: 'Chatweb Font';
    font-size: 16px;
    line-height: 30px;
    padding-left: 10px !important;
    margin-left: 5px;
    outline: none;
    width: 210px;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 0px transparent;
    }
`

const BotTextInputButton = styled.div`
    align-items: center;
    background-color: ${props => props.buttonColor};
    border-radius: 14px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
`

const BotTextInputButtonChervon = styled.img`
    padding-left: 1px;
    width: 18px !important;
    height: 18px !important;
`

const RetrySMSMessage = styled.div`
    color: red;
    font-family: 'Chatweb Font';
    font-size: 12px;
    margin-top: 5px;
    padding-left: 5px;
    max-width: 280px;
`

const ServerErrorMessage = styled.div`
    color: red;
    font-family: 'Chatweb Font';
    font-size: 16px;
    margin-top: 5px;
    padding-left: 10px;
`
