import { INT_SET_TEXT } from './types'

const initialState = {
  locale: 'es',
  translation: {
    initLoginMail: 'Hola! Para poder pasarte con un operador, necesitamos que nos confirmes tu correo electrónico.',
    initLoginSMS: 'Hola! Para poder pasarte con un operador, necesitamos que nos confirmes tu número de teléfono.',
    loginCodeMail: 'Gracias! Acabo de enviar un código de confirmación al correo electrónico registrado. Me puedes escribir el código aquí?',
    loginCodeSMS: 'Gracias! Acabo de enviar un código de confirmación por SMS a este número. Me puedes escribir el código aquí?',
    loginError: 'Ocurrió un error, por favor intentalo nuevamente',
    loginEmailError: 'El email ingresado no es válido',
    loginOk: 'Gracias por autenticarte 🤗',
    msgTitle: 'Nuevo Mensaje!',
    recordMedia: 'Tomar Foto o Video',
    recordAudio: 'Grabar Audio',
    modalMailError: 'Por favor ingresa un correo válido',
    modalMailTitle: 'Enviar esta conversación por mail',
    modalPlaceholderMail: 'Escribe aquí tu correo...',
    btnSend: 'Enviar',
    writing: 'Escribiendo...',
    writeHere: 'Escribe aquí...',
    placeholderMail: 'Ej nombre@correo.com',
    writeCode: 'Ingrese un código válido',
    loginNotCodeMail: '¿No recibiste el código? Revisa tu correo electrónico y vuelve a intentarlo',
    loginNotCodeSMS: '¿No recibiste el SMS? Revisa el número ingresado y vuelve a intentarlo',
    allowMicrophone: 'Por favor permita el uso del micrófono en su navegador',
    dateFormat: 'dd \'de\' MMMM \'de\' yyyy',
    dateTodayTitle: 'Hoy',
    letsSpeak: '¿Hablamos?',
    expiredConversation: 'Esta conversación ha expirado'
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
  case INT_SET_TEXT:
    return {
      locale: action.locale || state.locale,
      translation: action.translation || state.translation
    }
  default:
    return state
  }
}
