const DNS_API_WALL = process.env.REACT_APP_DNS_API_WALL || ''
const DNS_SOCKET = DNS_API_WALL.split('//')?.[1]
const isLocal = /localhost/.test(DNS_API_WALL)

export default {
  URL: DNS_API_WALL,
  SOCKET: {
    hostname: DNS_SOCKET,
    port: isLocal ? 5000 : 443,
    secure: !isLocal
  }
}
