import { INT_SET_TEXT } from './types'
import APIWall from '../../api/wall'

/**
 * Update lang
 * @param {Object} query?
 * @param {string} query.locale
 */
const updateLocale = (query = {}) => async (dispatch, getState) => {
  const { locale = 'es' } = query
  const { locale: oldLocale } = getState().internationalization

  if (locale === oldLocale) return true

  try {
    const { data } = await APIWall.getInternationalization(query)

    dispatch({
      type: INT_SET_TEXT,
      locale,
      translation: data
    })
  } catch ({ response = {}, message }) {
    const { data } = response
    console.error('translation', message || data)
  }

  return true
}

export default {
  updateLocale
}
