import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import ChatScreen from './ChatScreen.jsx'
import ChatMobileScreen from './ChatMobileScreen'

import userActions from '../redux/user/actions'
import authActions from '../redux/auth/actions'
import chatActions from '../redux/chat/actions'

import { channelToken } from '../services/CToken'
import { registerUser } from '../helpers/authHelpers'
import TrackingEvents from '../services/TrackingEvents'

import Sockets, { SocketWall } from '../socket'
import { baseImagesUrl } from '../constants'

let authType = ''

// more info https://dirask.com/posts/JavaScript-on-location-changed-event-on-url-changed-event-DKeyZj
const initListeners = () => {
    let pushState = window.history.pushState
    let replaceState = window.history.replaceState

    window.history.pushState = function () {
        pushState.apply(window.history, arguments)
        window.dispatchEvent(new Event('pushstate'))
        window.dispatchEvent(new Event('locationchange'))
    }

    window.history.replaceState = function () {
        replaceState.apply(window.history, arguments)
        window.dispatchEvent(new Event('replacestate'))
        window.dispatchEvent(new Event('locationchange'))
    }

    window.addEventListener('popstate', function () {
        window.dispatchEvent(new Event('locationchange'))
    })
}

const FloatingButton = () => {
  const [chatOpen, setChatOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const user = useSelector((state) => state.user)
  const chat = useSelector((state) => state.chat)
  const [cookies, setCookie, removeCookie] = useCookies(['chatweb-cookie'])
  const [width, setWidth] = React.useState(window.innerWidth)
  const dispatch = useDispatch()
  const [mode, setMode] = React.useState('normal')

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  React.useEffect(() => {
    dispatch(chatActions.getChannelInformation())
      .then((authenticationType) => {
        authType = authenticationType
        if (cookies['chatweb-cookie'] && cookies['chatweb-cookie'].channelToken === channelToken) {
          dispatch(userActions.userLogged(cookies['chatweb-cookie'].userId, authenticationType)).catch(() => {
            removeCookie('chatweb-cookie')
            userLogin(authenticationType)
          })
        } else if (authenticationType !== 'none') {
          dispatch(authActions.initializeUserLogin(authenticationType === 'sms' ? 'phone_auth' : authenticationType))
        }
      })
  }, [])

  React.useEffect(() => {
    if (chatOpen) {
      if (authType === 'none' && !cookies['chatweb-cookie']) {
        dispatch(authActions.anonymLogin()).then((userId) => {
          setCookie('chatweb-cookie', { userId, channelToken }, { path: '/' })
        })
      } // else {
      //     updateUserState(undefined, true)
      // }
    } // else {
    //     updateUserState()
    // }
    dispatch(chatActions.resetUnreadCounter())
  }, [chatOpen])

  React.useEffect(() => {
    if (user.userId && (chat.authenticationType === 'only_email' || chat.authenticationType === 'only_phone') && !cookies['chatweb-cookie']) {
      registerUser(user.userId, setCookie, chat.authenticationType)
    }

    Sockets.startAll()
    SocketWall.subscribeUser(user.userId)
    return () => {
      SocketWall.unsubscribeUser(user.userId)
      Sockets.destroyAll()
    }
  }, [user.userId])

  React.useEffect(() => {
    checkSchedule()
  }, [chat.displaySchedule])

  const userLogin = (authenticationType) => {
    if (authenticationType !== 'none') {
      dispatch(authActions.initializeUserLogin())
    } else {
      dispatch(authActions.anonymLogin()).then((userId) => {
        setCookie('chatweb-cookie', { userId, channelToken }, { path: '/' })
      })
    }
  }

  const checkSchedule = () => {
    if (!chat.displaySchedule || !Object.keys(chat.displaySchedule).length) {
      return setLoading(false)
    }

    const timezone = chat.displaySchedule.timeZone
    const datetime = DateTime.local().setLocale('en-US').setZone(timezone)
    const day = datetime.weekdayLong.toLocaleLowerCase()
    const scheduleDay = chat.displaySchedule[day] || {}

    if (!scheduleDay.isActive) return setLoading(true)

    const indexHour = scheduleDay.ranges.findIndex(range => {
      const startDate = DateTime.fromFormat(range.start, 'HH:mm').setZone(timezone)
      const endDate = DateTime.fromFormat(`${range.end}:59.999`, 'HH:mm:ss.SSS').setZone(timezone)

      return datetime > startDate && datetime < endDate
    })

    setLoading(indexHour === -1)
  }

  return (
    <Container loading={loading ? 1 : 0}>
      { chatOpen
        ? <>
          { width <= 768
            ? <ChatMobileScreen width={width} height={window.innerHeight} closeChat={() => setChatOpen(false)} />
            : <ChatScreen closeChat={() => setChatOpen(false)} mode={mode} setMode={setMode} />
          }
        </>
        : null
      }
      { chat.unreadCounter > 0
        ? <UnreadBubble>
          {chat.unreadCounter}
        </UnreadBubble>
        : null
      }
      <Button color={chat.primaryColor} onClick={() => {
        dispatch(chatActions.resetUnreadCounter())
        setChatOpen(!chatOpen)
      }
      }>
        <BubbleImage open={chatOpen} src={ chatOpen ? `${baseImagesUrl}times-solid.svg` : `${baseImagesUrl}webchat.svg` } alt={'close'} title={'Cerrar conversación'}/>
      </Button>
    </Container>
  )
}

export default FloatingButton

const Container = styled.div`
    align-items: flex-end;
    display: ${(props) => (props.loading ? 'none' : 'flex')};
    flex-direction: column;
    position: fixed;
    pointer-events: auto;
    right: 20px;
    bottom: 20px;
    z-index: 2147483644;
`

const UnreadBubble = styled.div`
    align-items: center;
    background-color: red;
    border-radius: 10px;
    color: white;
    display: flex;
    font-size: 13px;
    justify-content: center;
    line-height: 20px;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -5px;
    right: -5px;
`

const Button = styled.div`
    align-items: center;
    background-color: ${(props) => props.color};
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
`

const BubbleImage = styled.img`
    width: ${props => props.open ? '25px' : '30px'} !important;
`
