import { baseImagesUrl } from '../../constants'
import {
  CHANGE_OPERATOR,
  TOGGLE_CAMERA_MODAL,
  TOGGLE_MIC_MODAL,
  CLOSE_MODAL,
  ATTACH_AUDIO,
  ATTACH_VIDEO,
  ATTACH_PHOTO,
  SET_COMPANY_TOKEN,
  SET_CHAT_INFORMATION,
  PLAY_SOUND,
  STOP_SOUND,
  SET_OPERATOR_WRITING,
  SET_TAB_STATE,
  ADD_UNREAD_COUNTER,
  RESET_UNREAD_COUNTER,
  SET_SESSION_TOKEN,
  EXPIRE_CHAT
} from './types'

const initialState = {
  companyToken: '',
  modalViewOpen: '',
  companyLogo: '',
  primaryColor: '#6318E1',
  displaySchedule: {},
  backgroundImage: '',
  recordedAudio: null,
  recordedVideo: null,
  capturedPhoto: null,
  operatorInfo: {
    operatorName: '',
    operatorCompany: '',
    operatorPhoto: ''
  },
  companyTerms: '',
  privacyPolicies: '',
  waitingForText: '',
  receptionMsg: {},
  authMsg: {},
  authOkMsg: {},
  sound: {
    playing: false,
    file: ''
  },
  operatorWriting: false,
  isFocused: true,
  authenticationType: 'phone_auth',
  countryDialCode: 'es',
  unreadCounter: 0,
  botImage: '',
  sessionToken: '',
  isExpired: false
}

export default (state = initialState, action) => {
  switch (action.type) {
  case SET_COMPANY_TOKEN:
    return {
      ...state,
      companyToken: action.companyToken,
      isExpired: false
    }
  case SET_CHAT_INFORMATION:
    return {
      ...state,
      backgroundImage: action.data.backgroundImage,
      primaryColor: action.data.primaryColor ? action.data.primaryColor : state.primaryColor,
      displaySchedule: action.data.displaySchedule,
      companyLogo: action.data.company.logo,
      companyTerms: action.data.company.companyTerms,
      privacyPolicies: action.data.company.privacyPolicies,
      waitingForText: action.data.waitingForText,
      receptionMsg: action.data.receptionMsg || {},
      authMsg: action.data.authMsg || {},
      authOkMsg: action.data.authOkMsg || {},
      authenticationType: action.data.authenticationType,
      standaloneTitle: action.data.standaloneTitle,
      countryDialCode: action.data.countryDialCode || state.countryDialCode,
      botImage: action.data.botImage || `${baseImagesUrl}robot.svg`
    }
  case CHANGE_OPERATOR:
    return {
      ...state,
      operatorInfo: {
        operatorName: action.operatorInfo.operatorName ? action.operatorInfo.operatorName : state.operatorInfo.operatorName,
        operatorCompany: action.operatorInfo.operatorCompany ? action.operatorInfo.operatorCompany : state.operatorInfo.operatorCompany,
        operatorPhoto: action.operatorInfo.operatorPhoto ? action.operatorInfo.operatorPhoto : ''
      }
    }
  case TOGGLE_CAMERA_MODAL:
    return {
      ...state,
      modalViewOpen: state.modalViewOpen === '' ? 'camera' : ''
    }
  case TOGGLE_MIC_MODAL:
    return {
      ...state,
      modalViewOpen: state.modalViewOpen === '' ? 'mic' : ''
    }
  case CLOSE_MODAL:
    return {
      ...state,
      modalViewOpen: ''
    }
  case ATTACH_AUDIO:
    return {
      ...state,
      recordedAudio: action.audio
    }
  case ATTACH_VIDEO:
    return {
      ...state,
      recordedVideo: action.video
    }
  case ATTACH_PHOTO:
    return {
      ...state,
      capturedPhoto: action.photo
    }
  case 'RESET_AUTH':
    return {
      ...state,
      recordedAudio: null,
      recordedVideo: null,
      capturedPhoto: null,
      operatorInfo: {
        operatorName: '',
        operatorCompany: '',
        operatorPhoto: '',
        ...state.operatorInfo
      }
    }
  case PLAY_SOUND:
    return {
      ...state,
      sound: {
        playing: true,
        file: action.fileUrl
      }
    }
  case STOP_SOUND:
    return {
      ...state,
      sound: {
        playing: false,
        file: ''
      }
    }
  case SET_OPERATOR_WRITING:
    return {
      ...state,
      operatorWriting: action.operatorWriting
    }
  case SET_TAB_STATE:
    return {
      ...state,
      isFocused: action.isFocused
    }
  case ADD_UNREAD_COUNTER:
    return {
      ...state,
      unreadCounter: state.unreadCounter + 1
    }
  case RESET_UNREAD_COUNTER:
    return {
      ...state,
      unreadCounter: 0
    }
  case SET_SESSION_TOKEN:
    return {
      ...state,
      sessionToken: action.sessionToken,
      isExpired: false
    }
  case EXPIRE_CHAT:
    return {
      ...state,
      sessionToken: '',
      companyToken: '',
      isExpired: true
    }
  default:
    return state
  }
}
