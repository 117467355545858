import * as React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import FloatingButton from './FloatingButton.jsx'
import AudioRecorder from './AudioRecorder.jsx'
import chatActions from '../redux/chat/actions'

import PhotoAndVideoRecorder from './PhotoAndVideoRecorder.jsx'
import { baseImagesUrl } from '../constants/index.js'
import SoundComponent from './SoundComponent.jsx'

const originalTabTitle = document.title

const MainView = () => {
  const dispatch = useDispatch()
  const chat = useSelector(state => state.chat)
  const { translation } = useSelector(state => state.internationalization)
  const messages = useSelector(state => state.messages)

  const uploadAudio = (audio) => {
    dispatch(chatActions.attachAudioRecording(audio))
    dispatch(chatActions.closeModal())
  }

  const uploadVideo = (video) => {
    dispatch(chatActions.attachVideoRecording(video))
    dispatch(chatActions.closeModal())
  }

  const uploadPhoto = (photo) => {
    dispatch(chatActions.attachPhoto(photo))
    dispatch(chatActions.closeModal())
  }

  React.useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  React.useEffect(() => {
    if (!chat.isFocused) {
      document.title = translation.msgTitle
    }
  }, [messages])

  const onFocus = () => {
    dispatch(chatActions.setTabState(true))
    document.title = originalTabTitle
  }

  const onBlur = () => {
    dispatch(chatActions.setTabState(false))
  }

  return (
    <ViewContainer className="notranslate">
      { chat.modalViewOpen !== ''
        ? <>
          <DimOverlay />
          <ModalContainer>
            <Modal type={chat.modalViewOpen === 'camera' ? 'camera' : 'mic'}>
              <ModalHeader color={chat.primaryColor}>
                {chat.modalViewOpen === 'camera' ? translation.recordMedia : translation.recordAudio}
                <CloseIcon src={`${baseImagesUrl}close-icon-grey.svg`} onClick={() => dispatch(chatActions.closeModal())} alt={'close'} title={'Cerrar conversación'}/>
              </ModalHeader>
              { chat.modalViewOpen === 'camera'
                ? <PhotoAndVideoRecorder uploadPhoto={uploadPhoto} uploadVideo={uploadVideo} />
                : <AudioRecorder uploadAudio={uploadAudio} />
              }
            </Modal>
          </ModalContainer>
        </>
        : null
      }
      <FloatingButton />
      { chat.sound.playing
        ? <SoundComponent />
        : null
      }
    </ViewContainer>
  )
}

export default MainView

const ViewContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const DimOverlay = styled.div`
    background-color: #000000;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 2147483645;
`

const ModalContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: fixed;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    z-index: 2147483645;
`

const Modal = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 660px;
    height: ${(props) => props.type === 'camera' ? '530px' : '350px'};
`

const ModalHeader = styled.div`
    align-items: center;
    display: flex;
    flex: 0.2;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    color: ${(props) => props.color};
    font-family: "Chatweb Font";
    font-size: 18px;
    font-weight: bolder;
`

const CloseIcon = styled.img`
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
`
