/* eslint-disable no-case-declarations */
import { NEW_MESSAGE, ADD_WALL, REMOVE_LAST_MESSAGE } from './types'

const initialState = []

const filterMessage = (message) => {
  return message// .replace(/^(<([p>])>)/ig, '')
}

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_WALL:
    const filteredHtmlMessages = action.messages.map((message) => {
      return {
        ...message,
        text: filterMessage(message.text)
      }
    })
    return filteredHtmlMessages
  case NEW_MESSAGE:
    return state.concat([{
      ...action.message,
      text: filterMessage(action.message.text)
    }])
  case REMOVE_LAST_MESSAGE:
    const newMessages = state
    newMessages.pop()
    return newMessages
  case 'RESET_AUTH':
    return initialState
  default:
    return state
  }
}
