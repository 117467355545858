import { USER_LOGGED, USER_WALL_ID } from './types'

import messagesActions from '../messages/actions'
import { updateUserState } from '../../helpers/authHelpers'

const userLoggedAct = (userId) => ({
  type: USER_LOGGED,
  userId
})

const userLogged = (userId, authenticationType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(messagesActions.getUserWall(userId, authenticationType))
      .then(() => {
        dispatch(userLoggedAct(userId))
        updateUserState(userId, true)
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const addUserWall = wallId => ({
  type: USER_WALL_ID,
  wallId
})

export default {
  userLogged,
  addUserWall
}
