import { SMS_SENT, CODE_VALIDATED, SET_AUTH_ERROR } from './types'
import { NEW_MESSAGE, REMOVE_LAST_MESSAGE } from '../messages/types'

import chatActions from '../chat/actions'
import messagesActions from '../messages/actions'
import { updateUserState } from '../../helpers/authHelpers'

import WallApi from '../../api/wall'

const smsSendAct = (phoneToken) => ({
  type: SMS_SENT,
  phoneToken
})

const codeValidatedAct = (userId) => ({
  type: CODE_VALIDATED,
  userId
})

const resetStoreState = () => ({
  type: 'RESET_AUTH'
})

const initializeUserLoginAct = (type, chat, translation) => (dispatch) => {
  const localText = type.includes('email')
    ? translation.initLoginMail
    : type.includes('phone')
      ? translation.initLoginSMS
      : ''

  if (chat.receptionMsg.contentHtml || chat.receptionMsg.content) {
    dispatch({
      type: NEW_MESSAGE,
      message: {
        type,
        newType: 'bot',
        subType: 'initialStep',
        text: chat.receptionMsg.contentHtml || chat.receptionMsg.content,
        buttons: chat.receptionMsg.buttons || [],
        content: chat.receptionMsg.attachments || [],
        createdAt: new Date()
      }
    })
  }

  dispatch({
    type: NEW_MESSAGE,
    message: {
      type, // phone_auth
      newType: 'bot',
      subType: 'stepOne',
      text: chat.authMsg.contentHtml || chat.authMsg.content || localText,
      buttons: chat.authMsg.buttons || [],
      content: chat.authMsg.attachments || [],
      createdAt: new Date()
    }
  })
}

const setAuthError = (errorText, step) => ({
  type: SET_AUTH_ERROR,
  errorText,
  step
})

const initializeUserLogin = (type) => (dispatch, getState) => {
  const { chat, internationalization: { translation } } = getState()

  dispatch(resetStoreState())
  dispatch(initializeUserLoginAct(type, chat, translation))
}

const userLoginPhone = (userPhone, type) => (dispatch, getState) => {
  const { translation } = getState().internationalization

  const postData = {
    type: type === 'only_phone' ? 'phone' : type === 'only_email' ? 'email' : type, // 'phone',
    identifier: userPhone,
    createWall: true
  }
  WallApi.sendLoginSms(postData).then(({ data }) => {
    if (type === 'phone' || type === 'email') {
      dispatch(smsSent(data.token, type, userPhone))
    } else {
      dispatch({
        type: NEW_MESSAGE,
        message: {
          type: 'hidden',
          newType: 'customer',
          text: postData?.identifier,
          createdAt: new Date()
        }
      })
      dispatch(codeValidatedAct(data.userId))
    }
  }).catch((error) => {
    if (error.response.status === 400) {
      dispatch(userRegisterPhone(postData, type))
    } else {
      dispatch(setAuthError(translation.loginError, 1))
    }
  })
}

const userRegisterPhone = (postData, type) => (dispatch, getState) => {
  const { translation } = getState().internationalization

  WallApi.sendRegisterSms(postData).then(({ data }) => {
    if (type === 'phone' || type === 'email') { dispatch(smsSent(data.token, type, postData?.identifier)) } else {
      if (postData?.identifier) {
        dispatch({
          type: NEW_MESSAGE,
          message: {
            type: 'hidden',
            newType: 'customer',
            text: postData?.identifier,
            createdAt: new Date()
          }
        })
      }
      dispatch(codeValidatedAct(data.userId))
    }
  }).catch(error => {
    let errorMessage = translation.loginError
    const errorData = error.response.data
    if (errorData.details === 'Invalid email format received') errorMessage = translation.loginEmailError
    dispatch(setAuthError(errorMessage, 1))
  })
}

const smsSent = (token, type, value) => (dispatch, getState) => {
  const messages = getState().messages
  if (messages.length <= 3) {
    if (value) {
      dispatch({
        type: NEW_MESSAGE,
        message: {
          type: 'hidden',
          newType: 'customer',
          text: value,
          createdAt: new Date()
        }
      })
      if (type === 'phone' || type === 'email') {
        const { translation } = getState().internationalization

        dispatch({
          type: NEW_MESSAGE,
          newType: 'bot',
          message: {
            type: type === 'phone' ? 'phone_auth' : type,
            subType: 'stepTwo',
            text: type === 'email'
              ? `<p>${translation.loginCodeMail}</p>`
              : `<p>${translation.loginCodeSMS}</p>`,
            createdAt: new Date(),
            newType: 'bot'
          }
        })
      }
      dispatch(smsSendAct(token))
    }
    if (type === 'phone' || type === 'email') {
      const { translation } = getState().internationalization

      dispatch({
        type: NEW_MESSAGE,
        newType: 'bot',
        message: {
          type: type === 'phone' ? 'phone_auth' : type,
          subType: 'stepTwo',
          text: type === 'email'
            ? `<p>${translation.loginCodeMail}</p>`
            : `<p>${translation.loginCodeSMS}</p>`,
          createdAt: new Date(),
          newType: 'bot'
        }
      })
    }
    dispatch(smsSendAct(token))
  }
}

const validatePhone = (code, type) => (dispatch, getState) => {
  const { translation } = getState().internationalization

  return new Promise((resolve) => {
    const postData = {
      code,
      token: getState().auth.phoneToken,
      type
    }
    if (!getState().auth.registrationFinished) {
      WallApi.validatePhone(postData).then(({ data }) => {
        dispatch({
          type: NEW_MESSAGE,
          message: {
            type: 'hidden',
            newType: 'customer',
            text: code,
            createdAt: new Date()
          }
        })
        dispatch(codeValidated(data.userId, true, type))
        resolve(data.userId)
      }).catch(() => {
        dispatch(setAuthError(translation.loginError, 2))
      })
    }
  })
}

const codeValidated = (userId, shouldShowMessage = true, type = 'phone_auth') => (dispatch, getState) => {
  const { chat, internationalization: { translation } } = getState()
  if (shouldShowMessage) {
    if (['phone', 'email', 'only_phone', 'only_email'].includes(type)) {
      dispatch({
        type: NEW_MESSAGE,
        newType: 'bot',
        message: {
          type: type === 'phone' ? 'phone_auth' : type,
          newType: 'bot',
          subType: 'stepThree',
          text: chat.authOkMsg.contentHtml || chat.authOkMsg.content || translation.loginOk,
          buttons: chat.authOkMsg.buttons || [],
          content: chat.authOkMsg.attachments || [],
          createdAt: new Date()
        }
      })
    }
  }
  dispatch(codeValidatedAct(userId))
  setTimeout(() => {
    updateUserState(userId, true)
    dispatch(chatActions.changeOperator({ operatorName: chat.waitingForText }))
    const messagesExt = Object.assign([], getState().messages)
    dispatch(messagesActions.getUserWall(userId)).then(() => {
      dispatch(messagesActions.sendEvent(messagesExt)).then(() => {
        if (shouldShowMessage) dispatch(messagesActions.getUserWall(userId))
      }).catch((error) => {
        console.error('error: ', error)
      })
    }).catch((error) => {
      console.error('error: ', error)
    })
  }, 3000)
}

const anonymLogin = () => (dispatch) => {
  return new Promise((resolve) => {
    WallApi.anonymLogin({ createWall: true }).then(({ data }) => {
      dispatch(codeValidated(data.userId, false))
      resolve(data.userId)
    })
  })
}

const retryMessage = (userPhone, type) => (dispatch) => {
  dispatch({
    type: REMOVE_LAST_MESSAGE
  })
  dispatch(userLoginPhone(userPhone, type))
}

export default {
  initializeUserLogin,
  userLoginPhone,
  userRegisterPhone,
  validatePhone,
  anonymLogin,
  retryMessage,
  codeValidated
}
