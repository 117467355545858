import socketCluster from 'socketcluster-client'

export default class SocketConnection {
  /**
   * Constructor
   * @param {object} options All socketcluster-client.create options
   * @param {string} options.hostname
   * @param {number} options.port
   * @param {boolean} [options.secure]
   * @param {function} [socketEvents]
   */
  constructor (options = {}, socketEvents) {
    if (!options.hostname) throw new Error('hostname is required.')
    if (!options.port) throw new Error('port is required.')

    this._options = { ...options }
    this._socketEvents = socketEvents || this._defaultMsgSocketEvents
    this._socket = {}
  }

  /**
   * Display default message for undefined events
   */
  _defaultMsgSocketEvents () {
    console.warn('Socket event not defined.')
  }

  /**
   * Create socket connection
   */
  start () {
    if (this._socket.state === 'open') return

    // Initiate the connection to the server
    this._socket = socketCluster.create(this._options)
    this._socket.on('connect', () => console.info('Socket connected.'))
    this._socket.on('disconnect', () => console.info('Socket disconnected.'))
    this._socket.on('subscribeFail', (channelname) => console.error('Socket subscribeFail =>> ' + channelname))
  }

  subscribe (channel) {
    if (!this._socket) this.start()

    this._socket.subscribe(channel)
    this._socket.watch(channel, (data) => {
      this._socketEvents(data)
    })
  }

  subscribeUser (userChannel) {
    if (userChannel) {
      this.subscribe(`userF_${userChannel}`)
    }
  }

  unsubscribe (channel) {
    this._socket.unwatch(channel)
    this._socket.unsubscribe(channel)
  }

  unsubscribeUser (userChannel) {
    if (userChannel) {
      this.unsubscribe(`userF_${userChannel}`)
    }
  }

  // Remove socket
  destroy () {
    this._socket.destroy()
  }
}
