import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import wallApi from '../api/wall'

// eslint-disable-next-line no-undef
const { REACT_APP_STANDALONE } = process.env

const EmailSend = (props) => {
  const isStandalone = (REACT_APP_STANDALONE && REACT_APP_STANDALONE === 'true' && !props.isMobile)
  const [userEmail, setUserEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState('')
  const chat = useSelector((state) => state.chat)
  const user = useSelector((state) => state.user)
  const { translation } = useSelector(state => state.internationalization)

  const validateEmail = (email) => {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const sendConversationByEmail = () => {
    if (validateEmail(userEmail)) {
      const postBody = {
        email: userEmail,
        userId: user.userId
      }
      wallApi.sendConversationToEmail(postBody).then(() => {
        props.setShowEmailSend(false)
      }).catch(e => {
        console.error('Error: ', e)
      })
      setEmailError('')
    } else {
      setEmailError(translation.modalMailError)
    }
  }

  return (
    <>
      { props.showEmailSend
        ? <EmailSendModal isStandalone={isStandalone}>
          <EmailSendModalTitle>{translation.modalMailTitle}</EmailSendModalTitle>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
            <EmailInput
              placeholder={translation.modalPlaceholderMail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <SendEmailButton
              primaryColor={chat.primaryColor}
              onClick={sendConversationByEmail}
            >{translation.btnSend}</SendEmailButton>
          </div>
          { emailError !== ''
            ? <EmailErrorText>{emailError}</EmailErrorText>
            : null
          }
        </EmailSendModal>
        : null
      }
    </>
  )
}

export default EmailSend

const EmailSendModal = styled.div`
    background-color: white;
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.1);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    bottom: ${props => props.isStandalone ? '100px' : '140px'};
    right: ${props => props.isStandalone ? '525px' : '20px'};
`

const EmailSendModalTitle = styled.div`
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
`

const EmailInput = styled.input`
    border: none !important;
    border-color: transparent;
    color: #333333 !important;
    font-family: 'Chatweb Font';
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;
    outline: none;
    width: 210px;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 0px transparent;
    }
`

const SendEmailButton = styled.div`
    align-items: center;
    background-color: ${props => props.primaryColor};
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 75px;
    height: 30px;
`

const EmailErrorText = styled.div`
    color: red;
    font-size: 14px;
    font-weight: 500;
`
