import WALL_API from './wallChannel'

const baseImagesUrl = 'https://assets.l1l.co/chatweb/images/'
const baseSoundsUrl = 'https://assets.l1l.co/chatweb/sounds/'

export {
  WALL_API,
  baseImagesUrl,
  baseSoundsUrl
}
