import * as React from 'react'

import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

import store from './redux/store'
import MainView from './components/MainView'
import MainViewStandalone from './components/MainViewStandalone'
import { isStandalone, channelToken } from './services/CToken'

import './services/NxChatwebEvents'

// eslint-disable-next-line no-undef
const withToken = !!(isStandalone && channelToken)

function App () {
  if (isStandalone && !withToken) return null

  return (
    <CookiesProvider>
      <Provider store={store}>
        {
          isStandalone
            ? <MainViewStandalone />
            : <MainView />
        }
      </Provider>
    </CookiesProvider>
  )
}

export default App
