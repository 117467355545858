import axios from 'axios'
import store from '../redux/store'
import chatActions from '../redux/chat/actions'
import TrackingStorage from '../services/TrackingStorage'

const isStandalone = (process.env.REACT_APP_STANDALONE && process.env.REACT_APP_STANDALONE === 'true')
const channelToken = isStandalone ? window.location.pathname.split('/')[2] : document.getElementById('nixi1_chatbot')?.getAttribute('channelToken')

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  config => {
    const { chat } = store.getState()
    const trackId = TrackingStorage.getCid()
    const { sessionToken, companyToken } = chat
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'nx-channel-token': channelToken
    }
    if (companyToken) {
      config.headers.token = companyToken
    }
    if (trackId) {
      config.headers['nx-track-id'] = trackId
    }
    if (sessionToken) {
      config.headers['nx-session-token'] = sessionToken
    }

    return config
  }
)

axiosInstance.interceptors.response.use({},
  function (error) {
    if (error.response) {
      const { response = {} } = error
      const { status } = response
      switch (status) {
      case 440: {
        document.title = store.getState().internationalization.translation.expiredConversation
        store.dispatch(chatActions.expireChat())
        break
      }
      default:
        //
        break
      }
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
