import * as React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

import ChatScreenStandalone from './ChatScreenStandalone'
import ChatMobileScreen from './ChatMobileScreen.jsx'

import AudioRecorder from './AudioRecorder.jsx'
import chatActions from '../redux/chat/actions'
import userActions from '../redux/user/actions'
import authActions from '../redux/auth/actions'

import Sockets, { SocketWall } from '../socket'

import PhotoAndVideoRecorder from './PhotoAndVideoRecorder.jsx'
import { baseImagesUrl } from '../constants/index.js'
import SoundComponent from './SoundComponent'
import { registerUser } from '../helpers/authHelpers'

const originalTabTitle = document.title

const MainViewStandalone = () => {
  const chat = useSelector(state => state.chat)
  const user = useSelector((state) => state.user)
  const { translation } = useSelector(state => state.internationalization)
  const messages = useSelector(state => state.messages)
  const [cookies, setCookie, removeCookie] = useCookies(['chatweb-cookie'])
  const [width, setWidth] = React.useState(window.innerWidth)
  const [mode, setMode] = React.useState('normal')
  const dispatch = useDispatch()

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  React.useEffect(() => {
    dispatch(chatActions.getChannelInformation()).then((authenticationType) => {
      const cookie = cookies[`chatweb-cookie-standalone-${window.location.pathname.split('/')[2]}`]
      if (cookie) {
        dispatch(userActions.userLogged(cookie, authenticationType)).catch(() => {
          removeCookie(`chatweb-cookie-standalone-${window.location.pathname.split('/')[2]}`)
          userLogin(authenticationType)
        })
      } else {
        userLogin(authenticationType)
      }
    })
  }, [])

  React.useEffect(() => {
    if (user.userId && (chat.authenticationType === 'only_email' || chat.authenticationType === 'only_phone') && !cookies['chatweb-cookie']) {
      registerUser(user.userId, setCookie, chat.authenticationType)
    }
    Sockets.startAll()
    SocketWall.subscribeUser(user.userId)
    return () => {
      SocketWall.unsubscribeUser(user.userId)
      Sockets.destroyAll()
    }
  }, [user.userId])

  const userLogin = (authenticationType) => {
    if (authenticationType !== 'none' && authenticationType !== 'redirectFromChannel') {
      dispatch(authActions.initializeUserLogin(authenticationType === 'sms' ? 'phone_auth' : authenticationType))
    } else {
      dispatch(authActions.anonymLogin()).then((userId) => {
        setCookie(`chatweb-cookie-standalone-${window.location.pathname.split('/')[2]}`, userId, { path: '/' })
      })
    }
  }

  const uploadAudio = (audio) => {
    dispatch(chatActions.attachAudioRecording(audio))
    dispatch(chatActions.closeModal())
  }

  const uploadVideo = (video) => {
    dispatch(chatActions.attachVideoRecording(video))
    dispatch(chatActions.closeModal())
  }

  const uploadPhoto = (photo) => {
    dispatch(chatActions.attachPhoto(photo))
    dispatch(chatActions.closeModal())
  }

  const handleCompanyLogoError = (e) => {
    e.target.onerror = null
    e.target.src = `${baseImagesUrl}logo.png`
  }

  React.useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  React.useEffect(() => {
    if (!chat.isFocused) {
      document.title = translation.msgTitle
    }
  }, [messages])

  const onFocus = () => {
    dispatch(chatActions.setTabState(true))
    document.title = originalTabTitle
  }

  const onBlur = () => {
    dispatch(chatActions.setTabState(false))
  }

  return (
    <>
      { width <= 1224
        ? <ChatMobileScreen width={window.innerWidth} height={window.innerHeight} />
        : <ViewContainer className="notranslate" width={window.innerWidth} height={window.innerHeight} backgroundImage={chat.backgroundImage}>
          { chat.modalViewOpen !== ''
            ? <>
              <DimOverlay />
              <ModalContainer>
                <Modal type={chat.modalViewOpen === 'camera' ? 'camera' : 'mic'}>
                  <ModalHeader color={chat.primaryColor}>
                    {chat.modalViewOpen === 'camera' ? translation.recordMedia : translation.recordAudio}
                    <CloseIcon src={`${baseImagesUrl}close-icon-grey.svg`} onClick={() => dispatch(chatActions.closeModal())} alt={'close'} title={'Cerrar conversación'} />
                  </ModalHeader>
                  { chat.modalViewOpen === 'camera'
                    ? <PhotoAndVideoRecorder
                      uploadPhoto={uploadPhoto}
                      uploadVideo={uploadVideo}
                    />
                    : <AudioRecorder
                      uploadAudio={uploadAudio}
                    />
                  }
                </Modal>
              </ModalContainer>
            </>
            : null
          }
          <ChatScreenContainer>
            <CompanyLogo
              src={chat.companyLogo || `${baseImagesUrl}logo.png`}
              alt={'company'}
              title={'Compañia'}
              onError={handleCompanyLogoError}
            />
            {
              chat.isExpired
                ? (
                  <TitleText>{translation.expiredConversation}</TitleText>
                )
                : (
                  <>
                    <TitleText>{chat.standaloneTitle || translation.letsSpeak}</TitleText>
                    <ChatScreenStandalone mode={mode} setMode={setMode} />
                  </>
                )
            }
          </ChatScreenContainer>
          <TermsTextContainer>
            { chat.companyTerms !== '' &&
                            <TermsLink href={chat.companyTerms} target='_blank'>Términos y condiciones</TermsLink>
            }
            { chat.privacyPolicies !== '' &&
                            <TermsLink href={chat.privacyPolicies} target='_blank'>Política de privacidad</TermsLink>
            }
          </TermsTextContainer>
          <PoweredText>Powered by <a href='https://www.nixi1.com' target='_blank' rel="noreferrer">nixi1</a></PoweredText>
        </ViewContainer>
      }
      { chat.sound.playing
        ? <SoundComponent />
        : null
      }
    </>

  )
}

export default MainViewStandalone

const ViewContainer = styled.div`
    align-items: center;
    background-image: url(${props => props.backgroundImage});
    display: flex;
    background-color: #FAFAFA;
    justify-content: center;
    width: ${props => `${props.width}px`};
    height: ${props => `${props.height}px`};
`

const DimOverlay = styled.div`
    background-color: #000000;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 2147483642;
`

const ModalContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2147483643;
`

const Modal = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    width: 660px;
    height: ${props => props.type === 'camera' ? '530px' : '350px'};
`

const ModalHeader = styled.div`
    align-items: center;
    display: flex;
    flex: 0.2;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    color: ${props => props.color};
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: bolder;
`

const CloseIcon = styled.img`
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
`

const CompanyLogo = styled.img`
    height: 60px !important;
    margin-bottom: 15px;
`

const TitleText = styled.div`
    color: #000000;
    font-family: 'Raleway';
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 25px
`

const ChatScreenContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const TermsTextContainer = styled.div`
    position: fixed;
    bottom: 10px;
    left: 15px;
    opacity: 0.5;
`

const TermsLink = styled.a`
    margin-right: 15px;
    color: #333333;
    font-family: 'Raleway';
    font-size: 12px;
`

const PoweredText = styled.div`
    position: fixed;
    bottom: 10px;
    right: 15px;
    opacity: 0.5;
    color: #333333;
    font-family: 'Raleway';
    font-size: 12px;
`
