import wallAPI from '../api/wall'
import store from '../redux/store'

import TrackingStorage from './TrackingStorage'

const EVENTS_CONFIG = {
  history: 'trackingUser',
  trackEvent: 'trackingEvents',
  variableEvent: 'trackingEvents'
}

class TrackingEvents {
  constructor () {
    this._isInit = false
    this._referrer = TrackingStorage.getReferrer()
  }

  /**
   * Start event
   * @param {string} [conversationId]
   *
   * @returns Promise<string>
   */
  async start (conversationId) {
    try {
      this._isInit = false

      const { data: { externalId } } = await wallAPI.initStartEvent({
        event: 'start',
        data: conversationId ? { conversationId } : {}
      })

      TrackingStorage.set({
        cid: externalId
      })

      this._isInit = true

      return externalId
    } catch (error) {
      console.error('te_st', error.message)

      return ''
    }
  }

  /**
   * Send history events
   * @param {{
   *  url: string,
   *  title: string,
   *  referrer?: string,
   *  date?: string
   * }} data
   *
   * @retuns Promise<boolean>
   */
  async addHistory (data) {
    const { referrer, url = '', title = '', date } = data

    try {
      const config = this._trackingConfig()
      if (config.trackingUser === false) return true

      const cDate = date || new Date()
      const currentRef = referrer || this._referrer
      if (currentRef === url) return true

      // set next referrer
      this._referrer = url || referrer
      TrackingStorage.set({ ref: url || referrer })

      const payload = {
        event: 'history',
        data: {
          url,
          title,
          date: cDate,
          referrer: currentRef || ''
        }
      }

      // Todo ok, se envía el evento
      await this._sendEvent(payload)

      return true
    } catch (error) {
      console.error('te_ah', data, error.message)

      return false
    }
  }

  /**
   * Send history events
   * @param {object} data
   * @param {string} data.content
   *
   * @retuns Promise<boolean>
   */
  async addHistoryEvent (data) {
    const { content } = data

    try {
      if (!content) throw new Error('content is required.')
      if (typeof content !== 'string') throw new Error('content must be a string.')

      const config = this._trackingConfig()
      if (config.trackingEvents === false) return true

      const payload = {
        event: 'trackEvent',
        data: {
          content,
          url: window.location.href,
          title: window.document.title,
          date: new Date(),
          referrer: window.document.referrer || this._referrer
        }
      }

      // Todo ok, se envía el evento
      await this._sendEvent(payload)

      return true
    } catch (error) {
      console.error('te_ahe', data, error.message)

      throw error
    }
  }

  /**
   * Send history events
   * @param {object} data
   * @param {string} [data.name]
   * @param {string} [data.phone]
   * @param {string} [data.email]
   * @param {[{label: string, value: string}]} [data.variables]
   *
   * @retuns Promise<boolean>
   */
  async addVariableEvent (data) {
    const { name, phone, email, variables } = data

    try {
      const config = this._trackingConfig()
      if (config.trackingEvents === false) return true

      if (name && typeof name !== 'string') throw new Error('name must be a string.')
      if (phone && typeof phone !== 'string') throw new Error('phone must be a string.')
      if (email && typeof email !== 'string') throw new Error('email must be a string.')
      if (variables) {
        if (!Array.isArray(variables)) throw new Error('variables must be an array.')
        if (variables.length) {
          const isWrong = variables.find(e => {
            if (typeof e.label !== 'string') return true
            if (typeof e.value !== 'string') return true

            return false
          })

          if (isWrong) throw new Error('The content of the variables field is wrong, it should be [{label: string, value: string}]')
        }
      }

      const fields = {}
      if (variables) {
        fields.variables = variables.map(e => ({ label: e.label, value: e.value }))
      }
      if (name) fields.name = name
      if (phone) fields.phone = phone
      if (email) fields.email = email

      const payload = {
        event: 'variableEvent',
        data: {
          ...fields,
          date: new Date()
        }
      }

      if (Object.keys(fields).length) {
        // Todo ok, se envía el evento
        await this._sendEvent(payload)
      }

      return true
    } catch (error) {
      console.error('te_ave', data, error.message)

      throw error
    }
  }

  /**
   * Send events
   * @param {object} payload
   * @param {string} payload.event
   * @param {object} payload.data
   *
   * @returns Promise<boolean>
   */
  async _sendEvent (payload, attempts = 0) {
    try {
      // Número máximo de intentos
      if (attempts > 10) throw new Error('attemps limits.')
      if (!payload.event) throw new Error('event field is required.')

      const config = this._trackingConfig()
      const configValue = config[EVENTS_CONFIG[payload.event]]

      // Está desactivado
      if (configValue === false) return true

      // No se ha iniciado el seguimiento
      // ó Se ha iniciado el seguimiento, y aun no se ha seteado la configuración
      if (!this._isInit || configValue === null) {
        await wait(2)
        return this._sendEvent(payload, attempts + 1)
      }

      // No existe configuración
      if (!config.trackingUser) return true

      const { data } = await wallAPI.sendTrackingEvent(payload)

      if (data?.error) {
        if (data.type === 'error_reset_track') {
          await this.start()
        }

        await wait(2)
        return this._sendEvent(payload, attempts + 1)
      }

      return true
    } catch (error) {
      const { response, message } = error
      const dataError = response?.data

      console.error('te_se', message, dataError || '')

      if (dataError) {
        await wait(2)
        return this._sendEvent(payload, attempts + 1)
      }

      return false
    }
  }

  /**
   * Get tracking config
   * @returns {{
   *  trackingUser: null|boolean,
   *  trackingEvents: null|boolean
   * }}
   */
  _trackingConfig () {
    const { trackingUser, trackingEvents } = store?.getState()?.chat || {}

    return { trackingUser, trackingEvents }
  }
}

/**
 * Wait
 * @param {number} timeSeconds
 */
async function wait (timeSeconds) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeSeconds * 1000)
  })
}

export default new TrackingEvents()
