export const SET_COMPANY_TOKEN = 'SET_COMPANY_TOKEN'
export const CHANGE_OPERATOR = 'CHANGE_OPERATOR'
export const SET_CHAT_INFORMATION = 'SET_CHAT_INFORMATION'
export const TOGGLE_CAMERA_MODAL = 'TOGGLE_CAMERA_MODAL'
export const TOGGLE_MIC_MODAL = 'TOGGLE_MIC_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const ATTACH_AUDIO = 'ATTACH_AUDIO'
export const ATTACH_VIDEO = 'ATTACH_VIDEO'
export const ATTACH_PHOTO = 'ATTACH_PHOTO'
export const PLAY_SOUND = 'PLAY_SOUND'
export const STOP_SOUND = 'STOP_SOUND'
export const SET_OPERATOR_WRITING = 'SET_OPERATOR_WRITING'
export const SET_TAB_STATE = 'SET_TAB_STATE'
export const ADD_UNREAD_COUNTER = 'ADD_UNREAD_COUNTER'
export const RESET_UNREAD_COUNTER = 'RESET_UNREAD_COUNTER'
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN'
export const EXPIRE_CHAT = 'EXPIRE_CHAT'
