const stylesForChatScreenSize = ({ mode, isStandalone }) => {
  const styles = {}
  const MODE_SIZE_NORMAL = 550
  const MODE_SIZE_MEDIUM = 700
  const MODE_SIZE_LONG = 850
  let maxHeight = window.innerHeight
  if (!isStandalone) maxHeight -= 90

  switch (mode) {
  case 'normal':
    if (MODE_SIZE_NORMAL < maxHeight) {
      styles.height = MODE_SIZE_NORMAL
    } else {
      styles.height = maxHeight
    }
    break
  case 'medium':
    if (MODE_SIZE_MEDIUM < maxHeight) {
      styles.height = MODE_SIZE_MEDIUM
    } else {
      styles.height = maxHeight
    }
    break
  case 'long':
    if (MODE_SIZE_LONG < maxHeight) {
      styles.height = MODE_SIZE_LONG
    } else {
      styles.height = maxHeight
    }
    break
  default:
    break
  }

  return styles
}

export default stylesForChatScreenSize
