import * as React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import AudioPlayer from './AudioPlayer'

import { baseImagesUrl } from '../constants'

const File = (props) => {
  const chat = useSelector(state => state.chat)
  if (props.content.length === 1) {
    const { mimeType, mimeSubtype } = props.content[0] || {}
    const fileUrlSplitted = props.content[0].urlLong.split('/')

    switch (mimeType) {
    case 'image':
      return (
        <div>
          <Image src={props.content[0].urlLong} onClick={() => window.open(props.content[0].urlLong, '_blank')} alt={'file'} title={'Archivo de conversación'} />
        </div>
      )
    case 'application':
      return (
        <div style={{ position: 'relative' }} width='295' height='160'>
          <object
            data={props.content[0].urlLong + '#toolbar=0'}
            type={`${mimeType}/${mimeSubtype}`}
            width='100%'
            height='100%'
          >
            <AppFile onClick={() => window.open(props.content[0].urlLong, '_blank')}>
              <FileIconContainer>
                <FileIcon src={`${baseImagesUrl}file-regular.svg`} alt={'file-regular'} title={'Archivo'}/>
              </FileIconContainer>
              {(fileUrlSplitted[fileUrlSplitted.length - 1] || '').substring(0, 20)}
            </AppFile>
          </object>
          <div
            style={{ cursor: 'pointer', width: '100%', height: '100%', position: 'absolute', right: 19, top: 2 }}
            onClick={() => window.open(props.content[0].urlLong, '_blank')}
          />
        </div>
      )
    case 'video':
      return (
        <Video controls>
          <source src={props.content[0].urlLong} />
        </Video>
      )
    case 'audio':
      return (
        <AudioPlayer src={props.content[0].urlLong} color={chat.primaryColor} />
      )
    default:
      return null
    }
  } else {
    return (
      <>
        <MultipleMediaContainer>
          {props.content.map((file, index) => {
            const { mimeType } = file

            switch (mimeType) {
            case 'image':
              return <MultipleMediaImage key={`file-${index}`} src={file.urlLong} onClick={() => window.open(file.urlLong, '_blank')} alt={'image'} title={'Imagen'} />
            case 'video':
              return <MultipleMediaFile key={`file-${index}`} onClick={() => window.open(file.urlLong, '_blank')}><MultipleMediaFileIcon src={`${baseImagesUrl}video-solid.svg`} alt={'video'} title={'Video'}/></MultipleMediaFile>
            case 'application':
              return <MultipleMediaFile key={`file-${index}`} onClick={() => window.open(file.urlLong, '_blank')}><MultipleMediaFileIcon src={`${baseImagesUrl}file-regular.svg`} alt={'file'} title={'Archivo'}/></MultipleMediaFile>
            case 'audio':
              return <MultipleMediaFile key={`file-${index}`} onClick={() => window.open(file.urlLong, '_blank')}><MultipleMediaFileIcon src={`${baseImagesUrl}microphone-icon.svg`} alt={'audio'} title={'Audio'}/></MultipleMediaFile>
            default:
              return null
            }
          })}
        </MultipleMediaContainer>
      </>
    )
  }
}

export default File

const Image = styled.img`
    cursor: pointer;
    object-fit: contain;
    width: 100% !important;
`

const Video = styled.video`
    width: 100%;
    height: 200px;
`

const AppFile = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 50px;

    color: #333333;
    font-size: 14px;
    font-weight: bold;
`

const FileIconContainer = styled.div`
    align-items: center;
    background-color: rgba(100,17,219,0.1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
    height: 35px;
    width: 35px;
`

const FileIcon = styled.img`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 25px !important;
    height: 25px !important;
`

const MultipleMediaContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
`

const MultipleMediaImage = styled.img`
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
    overflow: hidden;
    margin-top: 10px;
    width: 47% !important;
    height: 100px !important;
`

const MultipleMediaFile = styled.div`
    align-items: center;
    background-color: rgba(100,17,219,0.1);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: 10px;
    width: 47%;
    height: 100px;
`

const MultipleMediaFileIcon = styled.img`
    width: 60% !important;
    height: 60% !important;
`
