import axios from '../helpers/axiosInstance'
import { WALL_API } from '../constants'

const { URL: BASE_URL } = WALL_API

const getCompanyToken = () => axios.get(`${BASE_URL}/channel/validate`)

const getChannelInformation = (token) => axios.get(`${BASE_URL}/channel/information`, {
  headers: {
    ...token
  }
})

const sendLoginSms = (postBody) => axios.post(`${BASE_URL}/users/login`, postBody)

const sendRegisterSms = (postBody) => axios.post(`${BASE_URL}/users/register`, postBody)

const validatePhone = (postBody) => axios.post(`${BASE_URL}/users/validate`, postBody)

const getUserWall = (params) => axios.get(`${BASE_URL}/walls`, {
  params
})

const getInternationalization = (params) => axios.get(`${BASE_URL}/internationalization`, {
  params: { ...params, platform: 'chatweb' }
})

const uploadFiles = (postBody) => axios.post(`${BASE_URL}/user/upload`, postBody)

const addNewUserMessage = (wallId, postBody) => axios.post(`${BASE_URL}/walls/${wallId}/events?whole=node`, postBody)

const anonymLogin = (postBody) => axios.post(`${BASE_URL}/users/register`, postBody)

const updateUserState = (postBody) => axios.post(`${BASE_URL}/user/online`, postBody)

const sendConversationToEmail = (postBody) => axios.post(`${BASE_URL}/sendMsgToEmail`, postBody)

const sendEvent = (wallId, postBody) => axios.post(`${BASE_URL}/walls/${wallId}/events/login`, postBody)

const initStartEvent = (postBody) => axios.post(`${BASE_URL}/user/track/events`, postBody)

const sendTrackingEvent = (postBody) => axios.post(`${BASE_URL}/user/track/events`, postBody)

const postUserButtonAction = (postBody) => axios.post(`${BASE_URL}/walls/messages/postback`, postBody)

export default {
  getCompanyToken,
  getChannelInformation,
  sendLoginSms,
  sendRegisterSms,
  validatePhone,
  getUserWall,
  uploadFiles,
  addNewUserMessage,
  anonymLogin,
  updateUserState,
  sendConversationToEmail,
  sendEvent,
  getInternationalization,
  postUserButtonAction,
  initStartEvent,
  sendTrackingEvent
}
