import { NEW_MESSAGE, ADD_WALL } from './types'

import WallApi from '../../api/wall'

import chatActions from '../chat/actions'
import userActions from '../user/actions'
import { differenceInCalendarDays } from 'date-fns'
import { formatDateLong, nodeForDate } from '../../helpers/authHelpers'

const DEFAULT_IMAGE = 'https://assets.l1l.co/chatweb/images/default.png'

function getUrlVisited () {
  if (!window?.location) return ''
  if (!window.location.origin) return ''

  return `${window.location.origin}${window.location.pathname || ''}`
}

const getUserWall = (userId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    WallApi.getUserWall({ userId })
      .then(({ data }) => {
        const { translation, locale } = getState().internationalization
        dispatch(userActions.addUserWall(data._id))

        let date = new Date()
        const today = new Date()
        const messagesCount = data.nodeInfos.length
        const messages = []
        data.nodeInfos.forEach((node, index) => {
          let lastMessage = false
          let lastIsToday = false

          // SETEA FECHA DE INICIO
          if (index === 0) {
            const curDate = new Date(node.createdAt)
            const messageText = differenceInCalendarDays(today, curDate) === 0
              ? translation.dateTodayTitle
              : formatDateLong(curDate, { locale, longText: translation.dateFormat })
            date = curDate
            const { type, messageContent = {}, _id, createdAt } = nodeForDate(messageText, curDate)
            messages.push({
              nodeId: _id,
              type,
              text: messageContent.messageText,
              content: messageContent.attachments,
              buttons: messageContent.buttons,
              createdAt
            })
          } else {
            const curDate = new Date(node.createdAt)
            let messageText = formatDateLong(curDate, { locale, longText: translation.dateFormat })
            if (messagesCount - 1 === index) {
              lastMessage = true
              if (differenceInCalendarDays(today, curDate) === 0) {
                lastIsToday = true
                messageText = translation.dateTodayTitle
              }
            }
            if (differenceInCalendarDays(date, curDate) !== 0) {
              date = curDate
              const { type, messageContent = {}, _id, createdAt } = nodeForDate(messageText, curDate)
              messages.push({
                nodeId: _id,
                type,
                text: messageContent.messageText,
                content: messageContent.attachments,
                buttons: messageContent.buttons,
                createdAt
              })
            }
          }

          // SETEA MENSAJE NORMAL
          const { postedBy = {}, type, messageContent = {}, createdAt } = node

          messages.push({
            type,
            text: messageContent.messageText,
            content: messageContent.attachments || [],
            buttons: messageContent.buttons || [],
            operatorPhoto: postedBy?.photoURL || DEFAULT_IMAGE,
            createdAt
          })

          // SETEA FECHA
          if (lastMessage && !lastIsToday) {
            const { type, messageContent = {}, _id, createdAt } = nodeForDate(translation.dateTodayTitle, today)
            messages.push({
              nodeId: _id,
              type,
              text: messageContent.messageText,
              content: messageContent.attachments,
              buttons: messageContent.buttons,
              createdAt
            })
          }
        })

        if (messages.length > 0) {
          dispatch(addWallMessages(messages, data._id))
        } else {
          dispatch(addNewOperatorMessage(nodeForDate(translation.dateTodayTitle, today)))
          const chat = getState().chat

          if (chat.receptionMsg.contentHtml || chat.receptionMsg.content) {
            const initialMessage = {
              postedBy: {
                photoURL: chat.botImage // `${baseImagesUrl}robot.svg`
              },
              type: 'operator',
              messageContent: {
                messageText: chat.receptionMsg.contentHtml || chat.receptionMsg.content,
                buttons: chat.receptionMsg.buttons || [],
                content: chat.receptionMsg.attachments || []
              },
              createdAt: new Date()
            }
            dispatch(addNewOperatorMessage(initialMessage))
          }
        }

        resolve()
      })
      .catch((error) => reject(error))
  })
}

const addWallMessages = (messages, wallId) => ({
  type: ADD_WALL,
  messages,
  wallId
})

const addNewOperatorMessage = (operatorMessage) => (dispatch) => {
  const { postedBy = {}, type, messageContent = {}, createdAt } = operatorMessage
  dispatch({
    type: NEW_MESSAGE,
    message: {
      type,
      text: messageContent.messageText || '',
      content: messageContent.attachments || [],
      buttons: messageContent.buttons || [],
      operatorPhoto: postedBy.photoURL || DEFAULT_IMAGE,
      createdAt
    }
  })
  dispatch(chatActions.addUnreadCounter())
}

const uploadFiles = (userId, files) => {
  return new Promise((resolve) => {
    const postBody = {
      userId
      // files,
    }
    const _files = []
    files.forEach((it, idx) => {
      const arr = it.content.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      _files.push(new File([u8arr], `file-${idx}.${mime.split('/')[1]}`, { type: mime }))
    })
    const formData = new FormData()
    formData.append('data', JSON.stringify(postBody))
    _files.forEach(it => {
      formData.append('files', it)
    })
    WallApi.uploadFiles(formData).then((response) => {
      resolve(response.data)
    }).catch((error) => {
      console.error('error: ', error)
    })
  })
}

const addNewUserMessage = (messageText, files) => async (dispatch, getState) => {
  const { user } = getState()
  let uploadedFiles = {}
  if (files.length > 0) {
    uploadedFiles = await uploadFiles(user.userId, files)
  }

  let postBody = {
    userId: user.userId,
    eventType: 'POST',
    wallNodeType: 'customer'
  }

  postBody.currentUrl = getUrlVisited()
  if (messageText) {
    postBody = {
      ...postBody,
      messageInfo: {
        eventType: 'POST',
        messageText
      }
    }
  }

  if (uploadedFiles.files) {
    postBody = {
      ...postBody,
      messageInfo: {
        ...postBody.messageInfo,
        eventType: 'POST',
        attachments: uploadedFiles.files.map((file) => {
          const mimeSplit = file.mime.split('/')

          const att = {
            urlLong: file.url,
            mimeType: mimeSplit[0],
            mimeSubtype: mimeSplit[1],
            name: file.filename || '',
            storageService: file.storage,
            needRefresh: file.needRefresh,
            size: file.size
          }
          if (file.fileId) att.fileId = file.fileId

          return att
        })
      }
    }
  }
  WallApi.addNewUserMessage(user.wallId, postBody).then(() => {
    dispatch(chatActions.playSendMessage())
  })
}

const sendEvent = (messages) => async (dispatch, getState) => {
  const { user } = getState()
  return new Promise((resolve) => {
    const _messages = []
    messages.filter(it => it.type !== 'date').forEach(it => {
      _messages.push(
        {
          messageContent: {
            attachments: it.attachments,
            buttons: it.buttons,
            messageText: it.text
          },
          type: it.newType,
          postedBy: null,
          isLoginMsg: true,
          eventType: 'POST',
          createdAt: it.createdAt
        }
      )
    })
    if (_messages.length > 0) {
      const body = {
        userId: user.userId,
        messages: _messages
      }
      WallApi.sendEvent(user.wallId, body)
        .then(() => {
          resolve(true)
        }).catch(error => {
          console.error('error: ', error)
          resolve(false)
        })
    }
  })
}

const buttonAction = ({ content }) => async (dispatch, getState) => {
  const { user: { userId } } = getState()
  return new Promise((resolve) => {
    WallApi.postUserButtonAction({ userId, content })
      .then(() => resolve(true))
      .catch(error => {
        console.error('error: ', error)
        resolve(false)
      })
  })
}

export default {
  getUserWall,
  addNewOperatorMessage,
  addNewUserMessage,
  sendEvent,
  buttonAction
}
