import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { hexToCSSFilter } from 'hex-to-css-filter'

import messageActions from '../redux/messages/actions'
import ChatBubble from './ChatBubble'
import FileAttached from './FileAttached'

import { baseImagesUrl } from '../constants'
import { registerUser } from '../helpers/authHelpers'

// import wallApi from '../api/wall'
import EmailSend from './EmailSendModal'

// eslint-disable-next-line no-undef
const isStandalone = (process.env.REACT_APP_STANDALONE && process.env.REACT_APP_STANDALONE === 'true')

const ChatMobileScreen = (props) => {
  const auth = useSelector((state) => state.auth)
  const chat = useSelector((state) => state.chat)
  const messages = useSelector((state) => state.messages)
  const { translation } = useSelector(state => state.internationalization)
  const user = useSelector((state) => state.user)
  const [, setCookie] = useCookies(['chatweb-cookie'])
  const [message, setMessage] = React.useState('')
  const [filesAttached, setFilesAttached] = React.useState([])
  const [showEmailSend, setShowEmailSend] = React.useState(false)
  // const [userEmail, setUserEmail] = React.useState('')
  // const [emailError, setEmailError] = React.useState('')
  const messagesContainer = React.createRef()
  let filePicker = React.useRef()
  const dispatch = useDispatch()

  const register = (inputValue, type) => {
    registerUser(inputValue, setCookie, type)
  }

  const sendMessage = () => {
    dispatch(messageActions.addNewUserMessage(`<p>${message}</p>`, filesAttached))
    setMessage('')
    setFilesAttached([])
  }

  const onChangeFile = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files[0]
    const result = await toBase64(file).catch(e => Error(e))
    if (result instanceof Error) {
      console.error('Error: ', result.message)
      return
    }
    const fileToUpload = {
      name: file.name,
      type: file.type,
      content: result
    }
    setFilesAttached([...filesAttached, fileToUpload])
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const removeAttachedFile = (indexToRemove) => {
    const newFilesAttached = filesAttached.filter((file, index) => {
      return index !== indexToRemove
    })
    setFilesAttached([...newFilesAttached])
  }

  return (
    <ChatWindow className="notranslate" width={props.width} height={props.height}>
      <Header color={chat.primaryColor}>
        <OperatorInfoContainer>
          <ProfileImage src={chat.operatorInfo.operatorPhoto === '' ? chat.botImage : chat.operatorInfo.operatorPhoto} alt={'profile-photo'} title={'Foto de Pefil'}/>
          <OperatorNameContainer>
            {
              chat.operatorInfo.operatorName !== ''
                ? <OperatorName>{chat.operatorInfo.operatorName}</OperatorName>
                : null
            }
            <OperatorCompany>{chat.operatorInfo.operatorCompany}</OperatorCompany>
          </OperatorNameContainer>
        </OperatorInfoContainer>
        { !isStandalone
          ? <CloseIcon src={`${baseImagesUrl}closeIcon.png`} onClick={props.closeChat} alt={'close'} title={'Cerrar conversación'} />
          : null
        }
      </Header>
      <ChatContainer>
        <MessagesContainer ref={messagesContainer}>
          { messages.map((message, index) => {
            return <ChatBubble key={index} message={message} registerUser={register} auth={auth} chat={chat} mobile={true} />
          })}
          { chat.operatorWriting
            ? <OperatorWritingText>{translation.writing}</OperatorWritingText>
            : null
          }
        </MessagesContainer>
      </ChatContainer>
      { user.userId !== ''
        ? <ChatInputContainer>
          <ChatInputSeparator />
          { filesAttached.length > 0
            ? <FilesAttachedContainer>
              { filesAttached.map((file, index) => {
                return (
                  <FileAttached file={file} key={index} removeAttached={() => removeAttachedFile(index)} />
                )
              })}
            </FilesAttachedContainer>
            : null
          }
          <InputContainer>
            <ChatInputOption
              src={`${baseImagesUrl}plus-solid.svg`}
              onClick={() => filePicker.click()}
              color={hexToCSSFilter(chat.primaryColor)}
              alt={'add'}
              title={'Agregar archivo'}
            />
            {
              chat?.authenticationType !== 'redirectFromChannel' && (
                <ChatInputOption
                  src={`${baseImagesUrl}envelope-solid.svg`}
                  onClick={() => {
                      setShowEmailSend(!showEmailSend)
                  }}
                  color={hexToCSSFilter(chat.primaryColor)}
                  alt={'send'}
                  title={'Enviar'}
                />
              )
            }
            <ChatInput
              value={message}
              // onKeyDown={(e) => getKeycode(e)}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={translation.writeHere}
            />
            <ChatSendButton
              color={(message !== '' || filesAttached.length > 0) ? chat.primaryColor : 'grey'}
              onClick={(message !== '' || filesAttached.length > 0) ? sendMessage : () => {}}
            >
              <ChatSendIcon src={`${baseImagesUrl}chevron-right-solid.svg`} alt={'send'} title={'Enviar'} />
            </ChatSendButton>
          </InputContainer>
        </ChatInputContainer>
        : null
      }
      <EmailSend showEmailSend={showEmailSend} setShowEmailSend={setShowEmailSend} isMobile />
      <input id="filePicker"
        type="file"
        ref={(ref) => (filePicker = ref)}
        style={{ display: 'none' }}
        onChange={(e) => onChangeFile(e)}
      />
    </ChatWindow>
  )
}

export default ChatMobileScreen

const ChatWindow = styled.div`
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    overflow-x: hidden;
    pointer-events: auto;
    width: ${props => `${props.width}px`};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`

const Header = styled.div`
    align-items: center;
    background-color: ${props => props.color};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 20px;
    height: 65px;
    top: 0;
`

const OperatorInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const ProfileImage = styled.img`
    background-color: #FFEFDA;
    border-radius: 21px;
    margin-right: 10px;
    padding: 3px;
    width: 32px !important;
    height: 32px !important;
`

const OperatorNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const OperatorName = styled.div`
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 800;
`

const OperatorCompany = styled.div`
    color: white;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
`

const CloseIcon = styled.img`
    cursor: pointer;
    opacity: 0.5;
    width: 18px !important;
    height: 18px !important;
`

const ChatContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
`

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: initial;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 85px;
    padding-bottom: 60px;
`

const ChatInputContainer = styled.div`
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    width: 100%;
`

const InputContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`

const ChatInputSeparator = styled.div`
    background-color: #E4E4E4;
    width: 100%;
    height: 2px;
`

const ChatInput = styled.input`
    border: none;
    border-color: transparent;
    color: #333333;
    display: flex;
    flex: 1;
    font-size: 16px;
    line-height: 30px;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 0px transparent;
    }
`

const ChatSendButton = styled.div`
    align-items: center;
    background-color: ${props => props.color};
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
`

const ChatSendIcon = styled.img`
    height: 20px !important;
`

const ChatInputOption = styled.img`
    cursor: pointer;
    filter: ${props => props.color};
    margin-right: 15px;
    height: 20px !important;
`

const FilesAttachedContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
`

const OperatorWritingText = styled.div`
    font-family: 'Chatweb Font';
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    opacity: 0.4;
`

// const EmailSendModal = styled.div`
//     background-color: white;
//     box-shadow: 0 8px 14px 0 rgba(0,0,0,0.1);
//     border-radius: 14px;
//     display: flex;
//     flex-direction: column;
//     padding-top: 15px;
//     padding-bottom: 10px;
//     padding-left: 20px;
//     padding-right: 20px;
//     position: absolute;
//     bottom: 70px;
//     right: 20px;
// `

// const EmailSendModalTitle = styled.div`
//     font-size: 16px;
//     font-weight: 800;
//     margin-bottom: 5px;
// `

// const EmailInput = styled.input`
//     border: none !important;
//     border-color: transparent;
//     color: #333333 !important;
//     font-family: 'Chatweb Font';
//     font-size: 16px;
//     line-height: 30px;
//     margin-bottom: 5px;
//     outline: none;
//     width: 210px;
//     &:focus {
//         outline: none;
//         box-shadow: 0px 0px 0px 0px transparent;
//     }
// `

// const SendEmailButton = styled.div`
//     align-items: center;
//     background-color: ${props => props.primaryColor};
//     border-radius: 15px;
//     color: white;
//     cursor: pointer;
//     display: flex;
//     justify-content: center;
//     width: 75px;
//     height: 30px;
// `

// const EmailErrorText = styled.div`
//     color: red;
//     font-size: 14px;
//     font-weight: 500;
// `
