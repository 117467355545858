import TrackingEvents from './TrackingEvents'

const EVENT_FUNCTION = {
  trackEvent: 'addHistoryEvent',
  variableEvent: 'addVariableEvent'
}

const EXTERNAL_EVENTS = Object.keys(EVENT_FUNCTION)

/**
 * Send event from external app
 * @param {'trackEvent'|'variableEvent'} eventName
 * @param {object} payload
 *
 * @returns {Promise<boolean>}
 */
const sendEvent = async (eventName, payload) => {
  try {
    if (!eventName) throw new Error('eventName is required.')
    if (!EXTERNAL_EVENTS.includes(eventName)) {
      throw new Error(`The eventName must be one of these: ${EXTERNAL_EVENTS.join(', ')}`)
    }

    return await TrackingEvents[EVENT_FUNCTION[eventName]](payload)
  } catch (error) {
    console.error('sendEvent', error)

    return false
  }
}

if (window) {
  window.nxchweb = {
    sendEvent
  }
}
